import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'react-quill/dist/quill.snow.css';
import Aboutimg from '../../../images/about-img.png'
import { isloading } from "../../../Services/Store/action";
import { ApiServices, MEDIA_URL } from "../../../APIS/apiservices";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const AboutUs = () => {

    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const [img, set_img] = useState({ file: '', url: '' })
    const [content, set_content] = useState({
        about_us: '',
        about_image: '',
        step_one_desc: '',
        step_two_desc: '',
        step_three_desc: '',
        for_repoters_desc: '',
        for_real_estate_agents_desc: '',
        for_conveyancer_desc: '',
        for_customers_desc: ''
    });
    const [status, set_status] = useState(true)

    useEffect(() => {
        get_content()
    }, [])

    function get_content() {
        dispatch(isloading(true))
        ApiServices.get_aboutus(selector.token).then((response) => {
            set_content({
                about_us: response.data.data.about_us,
                about_image: response.data.data.about_image,
                step_one_desc: response.data.data.step_one_desc,
                step_two_desc: response.data.data.step_two_desc,
                step_three_desc: response.data.data.step_three_desc,
                for_repoters_desc: response.data.data.for_repoters_desc,
                for_real_estate_agents_desc: response.data.data.for_real_estate_agents_desc,
                for_conveyancer_desc: response.data.data.for_conveyancer_desc,
                for_customers_desc: response.data.data.for_customers_desc
            })
            set_img({
                ...img,
                url: MEDIA_URL + response.data.data.about_image
            })
            set_status(true)
            dispatch(isloading(false))
        }).catch((error) => {
            console.log({ error })
            dispatch(isloading(false))
            toast.error(error.response.data.message)
        })
    }

    function handleImage(e) {
        let sFile = e.target.files[0]
        var fName = sFile.name
        var regex = new RegExp("(.*?)\.(jpg|jpeg|png)$");
        if (!(regex.test(fName))) {
            Swal.fire(
                '',
                'Please select valid image file.',
                'warning'
            )
            return;
        }
        set_img({
            file: e.target.files[0],
            url: URL.createObjectURL(e.target.files[0])
        })
    }

    function handleSubmit() {
        if (content.about_us.trim() === '' || content.step_one_desc.trim() === '' || content.step_two_desc.trim() === '' || content.for_repoters_desc.trim() === '' || content.for_real_estate_agents_desc.trim() === '' || content.for_conveyancer_desc.trim() === '' || content.for_customers_desc.trim() === '') {
            toast.error('Please fill all the fields')
            return false;
        }
        else {
            var data = new FormData()
            data.append("about_us", content.about_us)
            data.append("about_image", img.file)
            data.append("step_one_desc", content.step_one_desc)
            data.append("step_two_desc", content.step_two_desc)
            data.append("step_three_desc", content.step_three_desc)
            data.append("for_repoters_desc", content.for_repoters_desc)
            data.append("for_real_estate_agents_desc", content.for_real_estate_agents_desc)
            data.append("for_conveyancer_desc", content.for_conveyancer_desc)
            data.append("for_customers_desc", content.for_customers_desc)

            dispatch(isloading(true))
            ApiServices.update_aboutus(selector.token, data).then((response) => {
                get_content()
                dispatch(isloading(false))
                toast.success('About Us page updated successfully')
            }).catch((error) => {
                console.log({ error })
                dispatch(isloading(false))
                toast.error(error.response.data.message)
            })
        }
    }

    return (
        <>
            <div class="wrapper">
                <div class="content-wrapper">
                    <section class="content-header">
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-6">
                                    <h1>About Us</h1>
                                </div>
                                {status &&
                                    <div className="col-sm-6 text-right">
                                        <button type="button" class="btn btn-primary" onClick={() => set_status(!status)}>Edit</button>
                                    </div>}
                            </div>
                            {!selector.is_loading ?
                                <>
                                    <div className="row">
                                        <aside className="col-md-6">
                                            <div className="about-img">
                                                <img src={img.url} alt="" />
                                            </div>
                                            {!status &&
                                                <div className="abt-img-btn mt-1">
                                                    <input type="file" className="upload-file" onChange={(e) => handleImage(e)} accept="image/png, image/gif, image/jpeg" />
                                                    <button className="upload-butn mt-2">Choose Image</button>
                                                </div>}
                                        </aside>
                                        <aside className="col-md-6">
                                            {/* <label>About</label> */}
                                            <input type="text" style={{ cursor: "not-allowed" }} className="text-field" value="Prioritising property, not paperwork?" disabled={status} />
                                            <textarea className="input100 text-field" maxLength={880} placeholder="Enter Description Here." rows={18} value={content.about_us} disabled={status} onChange={(e) =>
                                                set_content({
                                                    ...content,
                                                    about_us: e.target.value
                                                })
                                            }>
                                            </textarea>
                                        </aside>
                                    </div>
                                    <div className="row choose-area">
                                        <aside className="col-md-4">
                                            {/* <label className="">Title</label> */}
                                            <input type="text" style={{ cursor: "not-allowed" }} className="text-field mb-2" value='Efficiency in simplicity ' disabled />
                                            {/* <input type="text" style={{ cursor: "not-allowed" }} className="text-field" value="How Does it Work?" disabled /> */}
                                        </aside>
                                        <aside className="col-md-8">
                                            {/* <label>Description</label> */}
                                            <textarea className="input100 text-field" style={{ cursor: "not-allowed" }} placeholder="Enter Description Here." rows={3} value='No need to pick up the phone, sign up or create an account. Get the ball rolling and we’ll handle the rest.' disabled />
                                        </aside>
                                    </div>
                                    <div className="curious_sec row">
                                        <aside className="col-md-6">
                                            <div className="curious_set">
                                                <div className="text-center d-flex justify-content-center">
                                                    <div className="p-3 aboutus-curious-no">
                                                        <p className="aboutus-curious-no-text">1</p>
                                                    </div>
                                                </div>
                                                <div className="set_curious_block text-center">
                                                    <textarea className="input100 text-field" maxLength={200} placeholder="Enter Description Here." rows={5} value={content.step_one_desc} disabled={status}
                                                        onChange={(e) =>
                                                            set_content({
                                                                ...content,
                                                                step_one_desc: e.target.value
                                                            })
                                                        }>
                                                    </textarea>
                                                </div>
                                            </div>
                                        </aside>
                                        <aside className="col-md-6">
                                            <div className="curious_set">
                                                <div className="text-center d-flex justify-content-center">
                                                    <div className="p-3 aboutus-curious-no">
                                                        <p className="aboutus-curious-no-text">2</p>
                                                    </div>
                                                </div>
                                                <div className="set_curious_block text-center">
                                                    <textarea className="input100 text-field" maxLength={200} placeholder="Enter Description Here." rows={5} value={content.step_two_desc} disabled={status} onChange={(e) =>
                                                        set_content({
                                                            ...content,
                                                            step_two_desc: e.target.value
                                                        })
                                                    }>
                                                    </textarea>
                                                </div>
                                            </div>
                                        </aside>
                                        {/* <aside className="col-md-4">
                                            <div className="curious_set">
                                                <div className="text-center d-flex justify-content-center">
                                                    <div className="p-3 aboutus-curious-no">
                                                        <p className="aboutus-curious-no-text">3</p>
                                                    </div>
                                                </div>
                                                <div className="set_curious_block text-center">
                                                    <textarea className="input100 text-field" maxLength={200} placeholder="Enter Description Here." rows={5} value={content.step_three_desc} disabled={status} onChange={(e) =>
                                                        set_content({
                                                            ...content,
                                                            step_three_desc: e.target.value
                                                        })
                                                    }>
                                                    </textarea>
                                                </div>
                                            </div>
                                        </aside> */}
                                    </div>

                                    <div className="carious_card_block set-cario-test row">
                                        <div className="col-xl-6 col-md-12">
                                            <div className="user_intro">
                                                <div className="aboutus-userinfo-card">
                                                    <input type="text" className="text-field mb-3" style={{ cursor: "not-allowed" }} value="For Buyers" disabled />
                                                    <textarea className="input100 text-field" maxLength={300} placeholder="Enter Description Here." rows={6} value={content.for_customers_desc} disabled={status} onChange={(e) =>
                                                        set_content({
                                                            ...content,
                                                            for_customers_desc: e.target.value
                                                        })
                                                    }>
                                                    </textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-12">
                                            <div className="user_intro">
                                                <div className="aboutus-userinfo-card">
                                                    <input type="text" className="text-field mb-3" style={{ cursor: "not-allowed" }} value="For Reporters" disabled />
                                                    <textarea className="input100 text-field" maxLength={300} placeholder="Enter Description Here." rows={6} value={content.for_repoters_desc} disabled={status} onChange={(e) =>
                                                        set_content({
                                                            ...content,
                                                            for_repoters_desc: e.target.value
                                                        })
                                                    }>
                                                    </textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-12">
                                            <div className="user_intro">
                                                <div className="aboutus-userinfo-card">
                                                    <input type="text" className="text-field mb-3" style={{ cursor: "not-allowed" }} value="For Real-estate Agents" disabled />
                                                    <textarea className="input100 text-field" maxLength={300} placeholder="Enter Description Here." rows={6} value={content.for_real_estate_agents_desc} disabled={status} onChange={(e) =>
                                                        set_content({
                                                            ...content,
                                                            for_real_estate_agents_desc: e.target.value
                                                        })
                                                    } >
                                                    </textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-12">
                                            <div className="user_intro">
                                                <div className="aboutus-userinfo-card">
                                                    <input type="text" className="text-field mb-3" style={{ cursor: "not-allowed" }} value="For Conveyancers and solicitors" disabled />
                                                    <textarea className="input100 text-field" maxLength={300} placeholder="Enter Description Here." rows={6} value={content.for_conveyancer_desc} disabled={status} onChange={(e) =>
                                                        set_content({
                                                            ...content,
                                                            for_conveyancer_desc: e.target.value
                                                        })
                                                    }>
                                                    </textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </>
                                :
                                <h4 className="text-center">Loading...</h4>}
                        </div>
                    </section>

                    {!status &&
                        <section class="content">

                            <p className="mb-2 text-center">
                                <button onClick={() => handleSubmit()} className="btn btn-success mr-2">Update</button>
                            </p>
                        </section>}
                </div>
            </div >
        </>
    );

}

export default AboutUs;
