import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import EditorToolbar, { modules, formats } from "./editor";
import 'react-quill/dist/quill.snow.css';
import { isloading } from "../../../Services/Store/action";
import { ApiServices } from "../../../APIS/apiservices";
import { toast } from "react-toastify";

const PrivacyPolicy = () => {

    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const [content, set_content] = useState('');

    useEffect(() => {
        get_content()
    }, [])

    function get_content() {
        dispatch(isloading(true))
        ApiServices.get_privacy_policy(selector.token).then((response) => {
            set_content(response.data.data.privacy_policy)
            dispatch(isloading(false))
        }).catch((error) => {
            console.log({ error })
            dispatch(isloading(false))
            toast.error(error.response.data.message)
        })
    }

    function handleSubmit() {
        if (content.trim === '') {
            toast.error('Please enter privacy policy')
        }
        else {
            var data = {
                "privacy_policy": content
            }
            dispatch(isloading(true))
            ApiServices.privacy_policy(selector.token, data).then((response) => {
                dispatch(isloading(false))
                toast.success('Privacy policy updated successfully')
            }).catch((error) => {
                console.log({ error })
                dispatch(isloading(false))
                toast.error(error.response.data.message)
            })
        }
    }

    function resetValue() {
        set_content('')
    }

    return (
        <>
            <React.Fragment>
                <div class="wrapper">
                    <div class="content-wrapper">
                        <section class="content-header">
                            <div class="container-fluid">
                                <div class="row mb-2">
                                    <div class="col-sm-6">
                                        <h1>Privacy Policy</h1>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {!selector.is_loading ?
                            <section class="content">
                                <EditorToolbar />
                                <ReactQuill
                                    theme="snow"
                                    value={content}
                                    onChange={(e) => set_content(e)}
                                    placeholder={"Enter Default Privacy Policy..."}
                                    modules={modules}
                                    formats={formats}
                                />
                                <p className="mt-4 mb-2">
                                    <button onClick={() => handleSubmit()} className="btn btn-success mr-2">Update</button>
                                    <button onClick={resetValue} className="btn btn-danger">Reset</button>
                                </p>
                            </section>
                            :
                            <h4 className="text-center">Loading...</h4>}
                    </div>
                </div>
            </React.Fragment>
        </>
    );

}

export default PrivacyPolicy;
