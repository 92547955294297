import { useEffect, useState } from "react";
import { BsEye, BsTrashFill } from "react-icons/bs";
import { FcSearch } from 'react-icons/fc'
import { useDispatch, useSelector } from "react-redux";
import { ApiServices } from "../../../APIS/apiservices";
import { useNavigate } from "react-router-dom";
import { isloading } from "../../../Services/Store/action";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Rating } from "react-simple-star-rating";
import Pagination from "react-js-pagination";
import { Button, Tab, Tabs } from "react-bootstrap";

const TestimonialList = () => {

    let navigate = useNavigate()
    let selector = useSelector((state) => state)
    const [search, set_search] = useState('')
    const dispatch = useDispatch();
    const [page, set_page] = useState(0)
    const [count, set_count] = useState(0)
    const [list, set_list] = useState([])
    const[status ,set_status]=useState("A")
    const[defaultKey ,setDefaultKey]=useState("approved")

    const handlesearch = (event) => {
        if (event.key === 'Enter') {
            get_list(search,status, page)
        }
    }

    function get_list(search,status ,page) {
        dispatch(isloading(true))
        ApiServices.get_testimonial(selector.token,status, search, page).then((response) => {
            set_count(response.data.result_count)
            set_list(response.data.data)
            dispatch(isloading(false))
        }).catch((error) => {
            dispatch(isloading(false))
            toast.error(error.response.data.message)
            console.log({ error })
        })
    }

    function DeleteArticle(id, i) {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#007bff',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                ApiServices.delete_testimonial(selector.token, id).then((response) => {
                    get_list(search,status, page)
                    toast.success('Testimonial deleted successfully')
                }).catch((error) => {
                    toast.error(error.response.data.message)
                })
            }
        })
    }
    function change_tab(e){
        // alert(e)
        setDefaultKey(e)
        if(e==="pending"){
            set_status("P")
            get_list(search ,"P",page)
        }
        else{
            set_status("A")
            get_list(search ,"A",page)  
        }
        
    }

    useEffect(() => {
        get_list(search, status ,page)
    }, [])

    return (
        <div class="wrapper">
            <div class="content-wrapper">
                <section class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h1>Testimonial List</h1>
                            </div>
                        </div>
                    </div>
                </section>
                {!selector.is_loading ?
                    <section class="content">
                        <div>
                            <input className='search-field' type="text" value={search} onChange={(e) => set_search(e.target.value)} placeholder='Search by user' onKeyDown={handlesearch} />
                            <FcSearch size={25} style={{ cursor: "pointer", marginLeft: "-34px" }} onClick={() => get_list(search,status, page)} />
                        </div>
                        <br />
                        <Tabs defaultActiveKey="approved" activeKey={defaultKey} id="uncontrolled-tab-example" className="mb-3" onSelect={(e)=>change_tab(e)}>
                            <Tab eventKey="approved" title="Approved" >
                                {/* <Sonnet /> */}
                            </Tab>
                            <Tab eventKey="pending" title="Pending" >
                                {/* <Sonnet /> */}
                            </Tab>
                        </Tabs>
                        {list.length > 0 ?
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>User Type</th>
                                <th>Testimonial Rating</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.length > 0 && list.map((data, index) => (
                                <tr>
                                    <td>{data.name}</td>
                                    <td>{data.user_type}</td>
                                    <td><Rating ratingValue={data.testimonial_rating * 20} readonly={true} /></td>
                                    <td>
                                        <p><span className="mr-1"><BsEye size={25} style={{ color: "#007bff", cursor: "pointer" }} onClick={() => navigate(`/testimonial?id=${data._id}`)} /></span>
                                            <span className="mr-1"><BsTrashFill size={25} style={{ color: "#007bff", cursor: "pointer" }} onClick={() => DeleteArticle(data._id, index)} /></span>
                                        </p></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    :
                    <h4 className="text-center">No data found</h4>}
            </section>
            :
            <h4 className="text-center">Loading...</h4>}
            {count > 0 ?
                <div className='ml-1 mt-3'>
                    <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={page + 1}
                        itemsCountPerPage={20}
                        totalItemsCount={count}
                        pageRangeDisplayed={5}
                        prevPageText="Previous"
                        nextPageText="Next"
                        onChange={(event) => {
                            set_page(event - 1)
                            get_list(search, status,event - 1)
                        }}
                    />
                </div>
                : ''}
        </div>
        </div >
    );

}

export default TestimonialList;
