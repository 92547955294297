import { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { ApiServices, MEDIA_URL } from "../../../APIS/apiservices";
import { isloading, useremail, userimage, username } from "../../../Services/Store/action";

const Profile = () => {

    const selector = useSelector((state) => state);
    const dispatch = useDispatch();
    const [name, set_name] = useState(selector.user_name);
    const [email, set_email] = useState(selector.user_email);
    const [user_img, set_user_img] = useState({ file: '', url: '' });
    const [password, set_password] = useState({ old: '', new: '', c_new: '' });

    function changePicture(e) {
        let sFile = e.target.files[0]
        var fName = sFile.name
        var regex = new RegExp("(.*?)\.(jpg|jpeg|png)$");
        if (!(regex.test(fName))) {
            Swal.fire(
                '',
                'Please select valid image file.',
                'warning'
            )
            return;
        }
        var Imgurl = URL.createObjectURL(sFile)
        set_user_img({ file: sFile, url: Imgurl })
    }

    function updatepass() {
        if (password.old === '') {
            toast.error('Please enter old password')
        }
        else if (password.new === '') {
            toast.error('Please enter new password')
        }
        else if (password.c_new === '') {
            toast.error('Please confirm password')
        }
        else if (password.new !== password.c_new) {
            toast.error('Password didn\'t matched')
        }
        else {
            var data = {
                "old_password": password.old,
                "new_password": password.new
            }
            dispatch(isloading(true))
            ApiServices.update_password(selector.token, data).then((response) => {
                toast.success('Password updated successfully')
                dispatch(isloading(false))
                set_password({ old: '', new: '', c_new: '' })
            }).catch((error) => {
                toast.error(error.response.data.message)
                dispatch(isloading(false))
            })
        }
    }

    function updateProfile() {
        if (name === '') {
            toast.error('Name field cannot be empty');
            return false
        }
        else if (email === '') {
            toast.error('Email field cannot be empty');
            return false
        }
        else {
            var formData = new FormData()
            formData.append('name', name)
            formData.append('email', email)
            formData.append('profile_image', user_img.file)
            dispatch(isloading(true))

            ApiServices.update_profile(selector.token, formData).then((response) => {
                dispatch(username(response.data.data.name))
                dispatch(useremail(response.data.data.email))
                dispatch(userimage(response.data.data.profile_image))
                sessionStorage.setItem('name', response.data.data.name);
                sessionStorage.setItem('email', response.data.data.email);
                sessionStorage.setItem('image', response.data.data.profile_image);
                dispatch(isloading(false))
                toast.success('Profile Updated Successfully')
            }).catch((error) => {
                console.log({ error })
                dispatch(isloading(false))
            })
        }
    }

    return (
        <div class="wrapper">
            <div class="content-wrapper">
                <section class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h1>Profile</h1>
                            </div>
                        </div>
                    </div>
                </section>

                {!selector.is_loading ?
                    <section class="content">
                        <Row>
                            <Col md={4} className="text-center profilebtn">
                                <div className="edit_bot_img m-auto">
                                    {
                                        user_img.url ?
                                            <img src={user_img.url} height="160" width="160" className="img-circle elevation-2" alt="User Image" />
                                            :
                                            <img src={selector.user_image ? MEDIA_URL + selector.user_image : "dist/img/user2-160x160.jpg"} height="160" width="160" className="img-circle elevation-2" alt="User Image" />
                                    }
                                    <input className="change-profile-input c-pointer" onChange={(e) => changePicture(e)} type="file" accept="image/jpg, image/jpeg, image/png" /><br />
                                </div>
                                <div>
                                    <b>Profile Picture</b>
                                </div>
                            </Col>
                            <Col md={8}>
                                <Form>
                                    <Form.Group className="mb-3" controlId="formBasicName">
                                        <Form.Label>NAME:</Form.Label>
                                        <Form.Control value={name} onChange={(e) => set_name(e.target.value)} type="text" placeholder="Enter name" className="input101" />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>EMAIL:</Form.Label>
                                        <Form.Control value={email} onChange={(e) => set_email(e.target.value)} type="email" placeholder="Enter email" className="input101" />
                                    </Form.Group>
                                    <Button className="border-0 my-3" onClick={updateProfile} variant="primary">Update</Button>
                                </Form>
                                <hr />
                                <Row>
                                    <Col md={12}>
                                        <Form>
                                            <Row>
                                                <Col md={12}>
                                                    <label className="my-3">CHANGE PASSWORD:</label>
                                                    <Form.Group className="mb-3" controlId="formBasicOldPassword">
                                                        <Form.Control type="password" placeholder="Enter Current Password"
                                                            value={password.old}
                                                            onChange={(e) => {
                                                                set_password({
                                                                    ...password,
                                                                    old: e.target.value
                                                                })
                                                            }}
                                                            className="input100" />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="mb-3 mt-2" controlId="formBasicPassword">
                                                        <Form.Control
                                                            value={password.new}
                                                            onChange={(e) => {
                                                                set_password({
                                                                    ...password,
                                                                    new: e.target.value
                                                                })
                                                            }}
                                                            type="password" placeholder="Enter New Password" className="input100" />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="mb-3 mt-2" controlId="formBasicPassword1">
                                                        <Form.Control
                                                            value={password.c_new}
                                                            onChange={(e) => {
                                                                set_password({
                                                                    ...password,
                                                                    c_new: e.target.value
                                                                })
                                                            }}
                                                            type="password" placeholder="Confirm New Password" className="input100" />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Button onClick={updatepass} variant="primary" className="my-3">
                                                        Update
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </section>
                    :
                    <h4 className="text-center">Loading...</h4>}
            </div>
        </div>
    );

}

export default Profile;
