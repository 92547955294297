import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ApiServices } from "../../../APIS/apiservices";
import Swal from 'sweetalert2';
import { isloading } from "../../../Services/Store/action";

const UploadReport = () => {

    let navigate = useNavigate()
    let selector = useSelector((state) => state);
    const dispatch = useDispatch();
    const [query, set_query] = useSearchParams();
    const [isSelected, setIsSelected] = useState(false)
    const [selectedFile, setSelectedFile] = useState('')
    const id = query.get('id')

    function select_file(e) {
        if (e.target.files[0].type == "application/pdf") {
            // if (e.target.files[0].size <= 10000000) {
                setSelectedFile(e.target.files[0]);
                setIsSelected(true);
            // }
            // else {
            //     Swal.fire(
            //         '',
            //         'PDF size should be less than 10 MB',
            //         'warning'
            //     )
            //     setSelectedFile('');
            //     setIsSelected(false);
            // }
        }
        else {
            Swal.fire(
                '',
                'Please upload PDF file only',
                'warning'
            )
            setSelectedFile('');
            setIsSelected(false);
        }
    }

    function submitFile() {

        const formData = new FormData();
        formData.append('id', id)
        formData.append('report_file', selectedFile)

        dispatch(isloading(true))

        ApiServices.upload_pdf(selector.token, formData).then((response) => {
            if (response.status === 200) {
                Swal.fire(
                    '',
                    'PDF Uploaded Successfully',
                    'success'
                )
                setTimeout(() => {
                    navigate(-1)
                }, 2000)
                dispatch(isloading(false))
            }
        }).catch((error) => {
            Swal.fire(
                '',
                error.response.data.message,
                'warning'
            )
            dispatch(isloading(false))
        })

    }

    return (
        <>
            <div class="wrapper">
                <div class="content-wrapper" style={{ position: "relative" }}>
                    <h4 className="upload-text">Upload Report File PDF.</h4>
                    <button type="button" className="upload-btn">Select file</button>
                    <input name="userfile" className="select-in" type="file" accept="application/pdf, application/vnd.ms-excel" onChange={(e) => select_file(e)} />
                    {isSelected &&
                        <div>
                            <p style={{ margin: "10px" }}>Filename: {selectedFile.name}</p>
                            {/* <p>Filetype: {selectedFile.type}</p>
                            <p>Size in bytes: {selectedFile.size}</p>
                            <p>
                                lastModifiedDate:{' '}
                                {selectedFile.lastModifiedDate.toLocaleDateString()}
                            </p> */}
                            <button onClick={submitFile} className="upload-btn">Upload File</button>
                        </div>
                    }
                </div>
            </div>
        </>
    );

}

export default UploadReport;
