import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiServices, MEDIA_URL } from "../../../APIS/apiservices";
import { isloading } from "../../../Services/Store/action";
import { Rating } from "react-simple-star-rating";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";

const Testimonial = () => {

    let selector = useSelector((state) => state)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [img, set_img] = useState('')
    const [img_url, set_img_url] = useState('')
    const [name, set_name] = useState('')
    const [content, set_content] = useState('')
    const [category, set_category] = useState('')
    const [query, set_query] = useSearchParams()
    const [id, set_id] = useState('')
    const [status, set_status] = useState(false)
    const [rating, set_rating] = useState(0)
    const [rate, set_rate] = useState(0);

    function handleImage(e) {
        let sFile = e.target.files[0]
        var fName = sFile.name
        var regex = new RegExp("(.*?)\.(jpg|jpeg|png)$");
        if (!(regex.test(fName))) {
            Swal.fire(
                '',
                'Please select valid image file.',
                'warning'
            )
            return;
        }
        set_img_url(URL.createObjectURL(e.target.files[0]))
        set_img(e.target.files[0])
    }

    function handleSubmit(e) {
        toast.dismiss()
        if (category === '') {
            toast.error('Please choose category.')
            return false;
        }
        // if (img === "") {
        //     toast.error('Select image to upload.')
        //     return false;
        // }
        if (name === '') {
            toast.error('Please enter the name.')
            return false;
        }
        if (content === "") {
            toast.error('Please enter the description.')
            return false;
        }
        if (rate <=0) {
            toast.error('Please select rating.')
            return false;
        }
        const data = new FormData()
        data.append('user_image', img)
        data.append('user_type', category)
        data.append('name', name)
        data.append("addedBy","Admin")
        data.append('testimonial_rating', rate)
        data.append('testimonial_desc', content)

        dispatch(isloading(true))
        ApiServices.add_testimonial(selector.token, data).then(response => {
            if (response.status === 200) {
                dispatch(isloading(false))
                toast.success("Successfully Added.")
                navigate('/testimonial_list')
            }
        }).catch(err => {
            dispatch(isloading(false))
            toast.error(err.response.data.message)
        })
    }

    function handleUpdate(e) {
        toast.dismiss()
        if (category === '') {
            toast.error('Please choose category.')
            return false;
        }
        if (name === '') {
            toast.error('Please enter the name.')
            return false;
        }
        if (content === "") {
            toast.error('Please enter the description.')
            return false;
        }
        const data = new FormData()
        data.append('user_image', img)
        data.append('user_type', category)
        data.append('name', name)
        // data.append("addedBy","Admin")
        data.append('testimonial_rating', rate)
        data.append('testimonial_desc', content)

        dispatch(isloading(true))
        ApiServices.update_testimonial(selector.token, id, data).then(response => {
            if (response.status === 200) {
                dispatch(isloading(false))
                toast.success("Testimonial Updated Successfully.")
                navigate('/testimonial_list')
            }
        }).catch(err => {
            dispatch(isloading(false))
            toast.error(err.response.data.message)
        })
    }

    function handleRating(e) {
        set_rating(e)
        set_rate(e / 20)
    }

    useEffect(() => {
        if (query != '') {
            dispatch(isloading(true))
            set_id(query.get('id'))
            ApiServices.view_testimonial(selector.token, query.get('id')).then((response) => {
                set_img_url( response.data.data.user_image)
                set_name(response.data.data.name)
                set_content(response.data.data.testimonial_desc)
                set_category(response.data.data.user_type)
                set_rate(response.data.data.testimonial_rating)
                set_rating(response.data.data.testimonial_rating * 20)
                dispatch(isloading(false))
                set_status(true)
            }).catch((error) => {
                console.log({ error })
                dispatch(isloading(false))
            })
        }
        else {
            set_img('')
            set_img_url('')
            set_category('')
            set_content('')
            set_name('')
            set_rate(0)
            set_rating(0)
            set_status(false)
        }
    }, [query])

    return (
        <div class="wrapper">
            <div class="content-wrapper">
                <section class="content-header">
                    {!selector.is_loading ?
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-12">
                                    <h4 className="mb-3">Testimonial</h4>
                                    {/* <div className="profile_box">
                                        <img src={img_url == '' ? require('../../../images/user_dummy.png') : MEDIA_URL +img_url} height="160" width="160" className="img-circle" alt="User Image" />
                                        <input onChange={(e) => handleImage(e)} type="file" disabled={status} accept="image/png, image/gif, image/jpeg" />
                                    </div> */}
                                     <div className="select_list">
                                       {/* <label>Type</label>
                                        <select className="" onChange={(e) => set_category(e.target.value)} value={category} disabled={status}>
                                            <option value=''>--select--</option>
                                            <option value='Client'>Client</option>
                                            <option value='Agent'>Agent</option>
                                        </select> */}
                                    {query != '' && status === true ?
                                        <Button onClick={() => set_status(!status)}>Edit</Button>
                                        : ""}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="blog_form mt-4" style={{ display: "block" }}>
                                                <div>
                                                    <label for="name">Name</label>
                                                    <input type="text" id="name" maxLength={25} placeholder="Enter Name" style={{ fontWeight: 'bold' }} onChange={(e) => set_name(e.target.value)} value={name} disabled={status}></input>
                                                </div>
                                                <div className="select-type  mt-3">
                                                    <div className="select_list">
                                                        <label>Type</label>
                                                        <select className="form-select" onChange={(e) => set_category(e.target.value)} value={category} disabled={status}>
                                                            <option value=''>--select--</option>
                                                            <option value='Client'>Client</option>
                                                            <option value='Agent'>Agent</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="mt-2">
                                                    <label for="name">Description</label>
                                                    <textarea className="input100" maxLength={150} placeholder="Enter Description Here." rows={4} onChange={(e) => set_content(e.target.value)} value={content} disabled={status}>

                                                    </textarea>
                                                </div>
                                                <div className="mt-2">
                                                    <label for="rating">Rating</label>
                                                    <Rating onClick={(e) => handleRating(e)} ratingValue={rating} readonly={status} />
                                                </div>
                                                {query == '' ?
                                                    <button type="submit" onClick={(e) => handleSubmit(e)}>Submit</button>
                                                    :
                                                    status == false ?
                                                        <button type="submit" onClick={(e) => handleUpdate(e)}>Approve and Publish</button> :
                                                        <></>

                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        :
                        <h4 className="text-center">Loading...</h4>}
                </section>
            </div>
        </div>
    );

}

export default Testimonial;
