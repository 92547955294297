import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiServices } from "../../../APIS/apiservices";
import { isloading } from "../../../Services/Store/action";

const FAQ = () => {

    const selector = useSelector((state) => state);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [faq_categories, set_faq_categories] = useState([]);
    const [category, set_category] = useState('')
    const [faq_question, set_faq_question] = useState('');
    const [faq_answer, set_faq_answer] = useState('');
    const [query, set_query] = useSearchParams()
    const [id, set_id] = useState('')
    const [status, set_status] = useState(false)

    useEffect(() => {
        if (query != '') {
            dispatch(isloading(true))
            set_id(query.get('id'))
            ApiServices.get_faq(selector.token, query.get('id')).then((response) => {
                console.log('RES --->>>', response.data.data)
                set_category(response.data.data.faq_category._id)
                set_faq_question(response.data.data.faq_question)
                set_faq_answer(response.data.data.faq_answer)
                set_status(true)
                dispatch(isloading(false))
            }).catch((error) => {
                console.log({ error })
                dispatch(isloading(false))
            })
        }
        else {
            set_category('')
            set_faq_answer('')
            set_faq_question('')
            set_status(false)
        }
        get_categories()
    }, [query])

    function get_categories() {
        dispatch(isloading(true))
        ApiServices.faq_categories(selector.token).then((response) => {
            set_faq_categories(response.data.data)
            dispatch(isloading(false))
        }).catch((error) => {
            console.log({ error })
            dispatch(isloading(false))
        })
    }

    function handleSubmit(e) {
        e.preventDefault()
        toast.dismiss()
        if (category === '') {
            toast.error('Please select a category');
            return false
        }
        else if (faq_question === '') {
            toast.error('Please enter question');
            return false
        }
        else if (faq_answer === '') {
            toast.error('Please enter answer');
            return false
        }
        else {
            var params = {
                "faq_question": faq_question,
                "faq_answer": faq_answer,
                "faq_category": category
            }
            ApiServices.add_faq(selector.token, params).then((response) => {
                toast.success('FAQ added successfully');
                navigate('/faq_list')
            }).catch((error) => {
                console.log({ error })
                toast.error(error.response.data.message)
            })
        }
    }

    function handleUpdate() {
        if (faq_question === '') {
            toast.error('Please enter question');
            return false
        }
        else if (faq_answer === '') {
            toast.error('Please enter answer');
            return false
        }
        else {
            var params = {
                "faq_question": faq_question,
                "faq_answer": faq_answer,
            }
            ApiServices.update_faq(selector.token, id, params).then((response) => {
                toast.success('FAQ updated successfully');
                navigate('/faq_list')
            }).catch((error) => {
                console.log({ error })
                toast.error(error.response.data.message)
            })
        }
    }

    return (
        <div class="wrapper">
            <div class="content-wrapper">
                <section class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h1>FAQs</h1>
                            </div>
                            <div class="col-sm-6 text-right">
                                {query != '' && status === true ?
                                    <Button onClick={() => set_status(!status)}>Edit</Button>
                                    : ""}
                            </div>
                        </div>
                    </div>
                </section>

                {!selector.is_loading ?
                    <section class="content">
                        <form className="add-catergory-form mt-5 mb-3">
                            <label for="formGroupExampleInput">Select Category</label>
                            <div className="form-group">
                                <select className="sub-select-category mb-3" onChange={(e) => set_category(e.target.value)} value={category} disabled={query != '' ? true : false}>
                                    <option value=''> Select category</option>
                                    {faq_categories.length > 0 && faq_categories.map((cat, i) => {
                                        return (<option value={cat._id}>{cat.category_name}</option>)
                                    })}

                                </select>
                            </div>

                            <div className="form-group">
                                <label for="formGroupExampleInput">Question</label>
                                <input type="text" maxLength={150} disabled={status} value={faq_question} onChange={(e) => set_faq_question(e.target.value.trimStart())} className="form-control" id="formGroupExampleInput" placeholder="Enter Question" />
                            </div>
                            <div className="form-group">
                                <label for="formGroupExampleInput2">Answer</label>
                                <textarea maxLength={350} value={faq_answer} disabled={status} onChange={(e) => set_faq_answer(e.target.value.trimStart())} className="form-control answer" id="formGroupExampleInput2" placeholder="Enter Answer" />
                            </div>
                            <p>
                                <span className="submit-bt">
                                    {query == '' ?
                                        <Button onClick={(e) => handleSubmit(e)}>Submit</Button>
                                        : status == false ? <Button onClick={(e) => handleUpdate(e)}>Update</Button> : ''}
                                </span>
                            </p>
                        </form>
                    </section>
                    :
                    <h4 className="text-center">Loading...</h4>}
            </div>
        </div>
    );

}

export default FAQ;
