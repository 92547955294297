import { useEffect, useState } from "react";
import { BsEye } from "react-icons/bs";
import { FcSearch } from 'react-icons/fc'
import { useDispatch, useSelector } from "react-redux";
import { ApiServices } from "../../../APIS/apiservices";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { isloading } from "../../../Services/Store/action";
import Pagination from "react-js-pagination";

const RequestList = () => {
    let navigate = useNavigate()
    let selector = useSelector((state) => state)
    const [search, set_search] = useState('')
    const dispatch = useDispatch();
    const [page, set_page] = useState(0)
    const [count, set_count] = useState(0)
    const [list, set_list] = useState([])

    useEffect(() => {
        get_requests(search, page);
    }, [])

    const get_requests = (search, page) => {
        dispatch(isloading(true))
        ApiServices.contact_us(selector.token, search, page).then((response) => {
            set_count(response.data.result_count)
            set_list(response.data.data);
            dispatch(isloading(false))
        }).catch((error) => {
            dispatch(isloading(false))
            console.log({ error })
        })

    }

    const handlesearch = (event) => {
        if (event.key === 'Enter') {
            get_requests(search, page)
        }
    }

    return (
        <>
            <div class="wrapper">
                <div class="content-wrapper">
                    <section class="content-header">
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-6">
                                    <h1>Contact Request List</h1>
                                </div>
                            </div>
                        </div>
                    </section>

                    {!selector.is_loading ?
                        <section class="content">
                            <div>
                                <input className='search-field' type="text" value={search} onChange={(e) => set_search(e.target.value)} placeholder='Search user by email or name' onKeyDown={handlesearch} />
                                <FcSearch size={25} style={{ cursor: "pointer", marginLeft: "-34px" }} onClick={() => get_requests(search, page)} />
                            </div>
                            <br />
                            {list.length > 0 ?
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone Number</th>
                                            <th>Type</th>
                                            <th>Message</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list.length > 0 && list.map((data, index) => (
                                            <tr>
                                                <td>{data.full_name}</td>
                                                <td>{data.email}</td>
                                                <td>{data.phone_number}</td>
                                                <td>{data.contact_person_type}</td>
                                                <td>{data.message}</td>
                                                {/* <td>
                                            <p><span className="mr-1"><BsEye size={25} style={{ color: "#007bff" }} onClick='' /></span>
                                            </p></td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                :
                                <h4 className="text-center">No request found</h4>}
                        </section>
                        :
                        <h4 className="text-center">Loading...</h4>}
                    {count > 0 ?
                        <div className='ml-1 mt-3'>
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={page + 1}
                                itemsCountPerPage={20}
                                totalItemsCount={count}
                                pageRangeDisplayed={5}
                                prevPageText="Previous"
                                nextPageText="Next"
                                onChange={(event) => {
                                    set_page(event - 1)
                                    get_requests(search, event - 1)
                                }}
                            />
                        </div>
                        : ''}
                </div>
            </div>
        </>
    );
}

export default RequestList;
