import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ApiServices, MEDIA_URL } from "../../../APIS/apiservices";
import pdfimg from '../../../images/pdfimg.png';
import { isloading } from "../../../Services/Store/action";

const ViewReport = () => {

    let selector = useSelector((state) => state);
    const [query, set_query] = useSearchParams();
    const id = query.get('id')
    const [data, set_data] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        get_detail()
    }, [])

    function get_detail() {
        dispatch(isloading(true))
        ApiServices.get_report_detail(selector?.token, id).then((response) => {
            set_data(response?.data?.data)
            dispatch(isloading(false))
        }).catch((error) => {
            console.log({ error })
            dispatch(isloading(false))
        })
    }

    return (
        <>
            <div class="content-wrapper">
                <section class="content-header">
                    <h3 style={{ marginLeft: '7px' }}>Report Details</h3>
                    <div class="container-fluid">
                        <div className="row">
                            <div className="col-md-10">
                                {!selector.is_loading ?
                                    <div className="user-right-side">
                                        <h5>Reference Company Name:<span>{data?.company_name_ref == "" || data?.company_name_ref == null ? (data?.company_id ? data?.company_id?.company_name : "N/A") : data?.company_name_ref}</span> </h5>
                                        {data?.order_report_type !== "D" &&
                                            <h5>Name:<span>
                                                {data?.order_report_type === 'S' ? data?.requesting_person_first_name + ' ' + data?.requesting_person_last_name :
                                                    data?.order_report_type === 'B&P' ? data?.name_for_building_and_pest :
                                                        data?.order_report_type === 'V' ? data?.first_name + ' ' + data?.last_name

                                                            : data?.order_report_type === "QS" ? data?.full_name_for_qs_report : data?.order_report_type === "DI" ? data?.dilapidation_report?.full_name_for_dilapidation_report : data?.order_report_type === "PIC" ? data?.pool_inspection_report?.full_name_for_pool_inspection_report :
                                                                data?.order_report_type === "HI" ? data?.handover_inspection_report?.full_name_for_handover_inspection_report : data?.order_report_type === "SI" ? data?.stage_inspection_report?.full_name_for_stage_inspection_report : ''}</span> </h5>}
                                        {data?.order_report_type !== "D" &&
                                            <h5>Email:<span>{data?.order_report_type === 'S' ? data?.requesting_person_email :
                                                data?.order_report_type === 'B&P' ? data?.email_for_building_and_pest :
                                                    data?.order_report_type === 'V' ? data?.email_id

                                                        : data?.order_report_type === "QS" ? data?.email_for_qs_report : data?.order_report_type === "DI" ? data?.dilapidation_report?.email_for_dilapidation_report : data?.order_report_type === "PIC" ? data?.pool_inspection_report?.email_for_pool_inspection_report :
                                                            data?.order_report_type === "HI" ? data?.handover_inspection_report?.email_for_handover_inspection_report : data?.order_report_type === "SI" ? data?.stage_inspection_report?.email_for_stage_inspection_report : ''}</span> </h5>}
                                        {data?.order_report_type !== "D" &&
                                            <h5>Phone:<span>{data?.order_report_type === 'S' ? data?.requesting_person_mobile_number :
                                                data?.order_report_type === 'B&P' ? data?.contact_number_for_building_and_pest :
                                                    data?.order_report_type === 'V' ? data?.mobile_number

                                                        : data?.order_report_type === "QS" ? data?.phone_number_for_qs_report : data?.order_report_type === "DI" ? data?.dilapidation_report?.phone_number_for_dilapidation_report : data?.order_report_type === "PIC" ? data?.pool_inspection_report?.phone_number_for_pool_inspection_report :
                                                            data?.order_report_type === "HI" ? data?.handover_inspection_report?.phone_number_for_handover_inspection_report : data?.order_report_type === "SI" ? data?.stage_inspection_report?.phone_number_for_stage_inspection_report : ''}</span> </h5>}
                                        {data?.order_report_type === 'S' &&
                                            <>
                                                {data?.agent_id !== null || data?.agent_first_name !== '' ?
                                                    <>
                                                        {data?.agent_first_name === '' ?
                                                            <>
                                                                <h5>Agent Name:<span>{data?.agent_id?.name}</span></h5>
                                                                <h5>Agent Email:<span>{data?.agent_id?.email}</span></h5>
                                                                <h5>Agent Phone Number:<span>{data?.agent_id?.phone_number}</span></h5>
                                                                <h5>Agent ABN No.:<span>{data?.agent_id?.abn_no}</span></h5>
                                                                <h5>Agent Agency:<span>{data?.agent_id?.agency}</span></h5>
                                                                <h5>Agent Address:<span>{data?.agent_id?.address}</span></h5>
                                                            </>
                                                            :
                                                            <>
                                                                <h5>Agent Name:<span>{data?.agent_first_name}</span></h5>
                                                                <h5>Agent Email:<span>{data?.agent_email}</span></h5>
                                                            </>
                                                        }
                                                    </>
                                                    : <></>
                                                }
                                                {data?.buyers_agent_id !== null || data?.buyers_agent_first_name !== '' ?
                                                    <>
                                                        {data?.buyers_agent_first_name === '' ?
                                                            <>
                                                                <h5>Buyer Agent Name:<span>{data?.buyers_agent_id?.name}</span></h5>
                                                                <h5>Buyer Agent Email:<span>{data?.buyers_agent_id?.email}</span></h5>
                                                                <h5>Buyer Agent Phone Number:<span>{data?.buyers_agent_id?.phone_number}</span></h5>
                                                                <h5>Buyer Agent ABN No.:<span>{data?.buyers_agent_id?.abn_no}</span></h5>
                                                                <h5>Buyer Agent Agency:<span>{data?.buyers_agent_id?.agency}</span></h5>
                                                                <h5>Buyer Agent Address:<span>{data?.buyers_agent_id?.address}</span></h5>
                                                            </>
                                                            :
                                                            <>
                                                                <h5>Buyer Agent Name:<span>{data?.buyers_agent_first_name}</span></h5>
                                                                <h5>Buyer Agent Email:<span>{data?.buyers_agent_email}</span></h5>
                                                            </>
                                                        }
                                                    </>
                                                    : <></>
                                                }
                                                {data?.solicitor_agent_id !== null || data?.solicitor_agent_first_name !== '' ?
                                                    <>
                                                        {data?.solicitor_agent_first_name === '' ?
                                                            <>
                                                                <h5>Solicitor Agent Name:<span>{data?.solicitor_agent_id?.name}</span></h5>
                                                                <h5>Solicitor Agent Email:<span>{data?.solicitor_agent_id?.email}</span></h5>
                                                                <h5>Solicitor Agent Phone Number:<span>{data?.solicitor_agent_id?.phone_number}</span></h5>
                                                                <h5>Solicitor Agent ABN No.:<span>{data?.solicitor_agent_id?.abn_no}</span></h5>
                                                                <h5>Solicitor Agent Agency:<span>{data?.solicitor_agent_id?.agency}</span></h5>
                                                                <h5>Solicitor Agent Address:<span>{data?.solicitor_agent_id?.address}</span></h5>
                                                            </>
                                                            :
                                                            <>
                                                                <h5>Solicitor Agent Name:<span>{data?.solicitor_agent_first_name}</span></h5>
                                                                <h5>Solicitor Agent Email:<span>{data?.solicitor_agent_email}</span></h5>
                                                            </>
                                                        }
                                                    </>
                                                    : <></>
                                                }
                                                {data?.order_type !== "O" &&
                                                    <h5>Report Required By:<span>{data?.inspection_date}</span></h5>
                                                }
                                                {data?.purchasing_report_on_behalf_on_someone === "NO" &&
                                                    <h5>Have Buyer Agent:<span>{data?.do_have_buyers_agent}</span></h5>}
                                                {data?.do_have_buyers_agent === 'NO' || data?.do_have_buyers_agent === 'no' &&
                                                    <h5>Need Buyer Agent Recommendation:<span>{data?.need_us_to_recommend}</span></h5>}
                                                {data?.purchasing_report_on_behalf_on_someone === "NO" &&
                                                    <h5>Have Solicitor Agent:<span>{data?.do_have_solicitor_agent}</span></h5>}
                                                {data?.have_organised_finanace === 'NO' || data?.have_organised_finanace === 'no' &&
                                                    <h5>Need Broker Recommendation:<span>{data?.like_us_to_reccomend_broker}</span></h5>
                                                }
                                                {data?.do_have_solicitor_agent === 'no' || data?.do_have_solicitor_agent === 'NO' &&
                                                    <h5>Need Solicitor Agent Recommendation:<span>{data?.need_us_to_recommend_solicitor_agent}</span></h5>}

                                                {data?.purchasing_report_on_behalf_on_someone === "NO" &&
                                                    <h5>Have Organised Finanace:<span>{data?.have_organised_finanace}</span></h5>}
                                                <h5>Purchasing Report on behalf of someone:<span>{data?.purchasing_report_on_behalf_on_someone}</span></h5>
                                                {data?.purchasing_report_on_behalf_on_someone === "YES" &&
                                                    <h5>Profession:<span>{data?.profession_of_person_on_whom_behalf}</span></h5>
                                                }
                                                {data?.purchasing_report_on_behalf_on_someone === "YES" && data?.professional_agent_id !== null &&
                                                    <>
                                                        <h5>Selected Profession Agent Name:<span>{data?.professional_agent_id?.name}</span></h5>
                                                        <h5>Selected Profession Agent Email:<span>{data?.professional_agent_id?.email}</span></h5>
                                                    </>
                                                }
                                                {data?.purchasing_report_on_behalf_on_someone === "YES" && data?.profession_of_person_on_whom_behalf !== "real estate agent" && data?.professional_agent_id === null &&
                                                    <>
                                                        <h5>Profession Agent Name:<span>{data?.professional_agent_first_name}</span></h5>
                                                        <h5>Profession Agent Email:<span>{data?.professional_agent_email}</span></h5>
                                                    </>
                                                }
                                                {data?.profession_of_person_on_whom_behalf === "others" &&
                                                    <h5>Profession:<span>{data?.profession_other_desc_if_selected}</span></h5>
                                                }
                                                {/* {data?.purchasing_report_on_behalf_on_someone === "NO" &&
                                                    <h5>Current Living Situation:<span>{data?.current_living_situation}</span></h5>} */}

                                                {data?.purchasing_report_on_behalf_on_someone === "NO" &&
                                                    <h5>Purpose For Purchasing:<span>{data?.reason_of_report}</span></h5>}
                                                {/* {data?.purchasing_report_on_behalf_on_someone === 'YES' || data?.purchasing_report_on_behalf_on_someone === 'yes' &&
                                                <h5>Agent Name:<span>{data?.purchasing_report_on_behalf_on_someone}</span></h5>} */}
                                                {data?.order_type !== "O" &&
                                                    <h5>Authority letter:<span><a href={MEDIA_URL + data?.authority_letter_for_report} target='_blank' download><img src={pdfimg} alt="" width="30" /></a></span></h5>
                                                }
                                            </>
                                        }
                                        {data?.order_report_type === 'B&P' &&
                                            <>
                                                <h5>Property Type:<span>{data?.property_type_for_building_and_pest}</span></h5>
                                                <h5>Total Bathrooms:<span>{(data?.bathrooms_count_for_building_and_pest).replace(/_/g, ' ')}</span></h5>
                                                <h5>Address:<span>{data?.address_for_building_and_pest}</span></h5>
                                                {/* <h5>Property Manager Name:<span>{data.property_manager_name_for_building_and_pest}</span></h5>
                                                <h5>Property Manager Phone:<span>{data.property_manager_number_for_building_and_pest}</span></h5> */}
                                                <h5>Report Type:<span>{(data?.report_type_for_building_and_pest).replace(/_/g, ' ')}</span></h5>
                                                <h5>Report Required By:<span>{data?.inspection_date_for_building_and_pest}</span></h5>
                                                {data?.agent_first_name_for_building_and_pest && data?.agent_id_for_building_and_pest ?
                                                    <>
                                                        <h5>Agent Name:<span>{data?.agent_id_for_building_and_pest?.name}</span></h5>
                                                        <h5>Agent Email:<span>{data?.agent_id_for_building_and_pest?.email}</span></h5>
                                                        <h5>Agent Phone Number:<span>{data?.agent_id_for_building_and_pest?.phone_number}</span></h5>
                                                        <h5>Agent ABN No.:<span>{data?.agent_id_for_building_and_pest?.abn_no}</span></h5>
                                                        <h5>Agent Agency:<span>{data?.agent_id_for_building_and_pest?.agency}</span></h5>
                                                        <h5>Agent Address:<span>{data?.agent_id_for_building_and_pest?.address}</span></h5>
                                                    </>
                                                    :
                                                    <>
                                                        <h5>Agent Name:<span>{data?.agent_first_name_for_building_and_pest ? data?.agent_first_name_for_building_and_pest : "N/A"}</span></h5>
                                                        <h5>Agent Email:<span>{data?.agent_email_for_building_and_pest ? data?.agent_email_for_building_and_pest : "N/A"}</span></h5>
                                                    </>
                                                }
                                                <h5>Have Buyer Agent:<span>{data?.do_have_buyers_agent_for_building_and_pest}</span></h5>
                                                {data?.do_have_buyers_agent_for_building_and_pest === 'no' || data?.do_have_buyers_agent_for_building_and_pest === 'NO' &&
                                                    <h5>Need Buyer Agent Recommendation:<span>{data?.need_us_to_recommend_for_building_and_pest}</span></h5>}
                                                {data?.do_have_buyers_agent_for_building_and_pest === "YES" &&
                                                    <>
                                                        {
                                                            data?.buyers_agent_first_name_for_building_and_pest === '' ?
                                                                <>
                                                                    <h5>Buyer Agent Name:<span>{data?.buyers_agent_id_for_building_and_pest?.name}</span></h5>
                                                                    <h5>Buyer Agent Email:<span>{data?.buyers_agent_id_for_building_and_pest?.email}</span></h5>
                                                                    <h5>Buyer Agent Phone Number:<span>{data?.buyers_agent_id_for_building_and_pest?.phone_number}</span></h5>
                                                                    <h5>Buyer Agent ABN No.:<span>{data?.buyers_agent_id_for_building_and_pest?.abn_no}</span></h5>
                                                                    <h5>Buyer Agent Agency:<span>{data?.buyers_agent_id_for_building_and_pest?.agency}</span></h5>
                                                                    <h5>Buyer Agent Address:<span>{data?.buyers_agent_id_for_building_and_pest?.address}</span></h5>
                                                                </>
                                                                :
                                                                <>
                                                                    <h5>Buyer Agent Name:<span>{data?.buyers_agent_first_name_for_building_and_pest}</span></h5>
                                                                    <h5>Buyer Agent Email:<span>{data?.buyers_agent_email_for_building_and_pest}</span></h5>
                                                                </>
                                                        }
                                                    </>}
                                                <h5>Have Solicitor Agent:<span>{data?.do_have_solicitor_agent_for_building_and_pest}</span></h5>
                                                {data?.do_have_solicitor_agent_for_building_and_pest === 'no' || data?.do_have_solicitor_agent_for_building_and_pest === 'NO' &&
                                                    <h5>Need Solicitor Agent Recommendation:<span>{data?.need_us_to_recommend_solicitor_agent_for_building_and_pest}</span></h5>}
                                                {data?.do_have_solicitor_agent_for_building_and_pest === "YES" &&
                                                    <>
                                                        {data?.solicitor_agent_first_name_for_building_and_pest === '' ?
                                                            <>
                                                                <h5>Solicitor Agent Name:<span>{data?.solicitor_agent_id_for_building_and_pest?.name}</span></h5>
                                                                <h5>Solicitor Agent Email:<span>{data?.solicitor_agent_id_for_building_and_pest?.email}</span></h5>
                                                                <h5>Solicitor Agent Phone Number:<span>{data?.solicitor_agent_id_for_building_and_pest?.phone_number}</span></h5>
                                                                <h5>Solicitor Agent ABN No.:<span>{data?.solicitor_agent_id_for_building_and_pest?.abn_no}</span></h5>
                                                                <h5>Solicitor Agent Agency:<span>{data?.solicitor_agent_id_for_building_and_pest?.agency}</span></h5>
                                                                <h5>Solicitor Agent Address:<span>{data?.solicitor_agent_id_for_building_and_pest?.address}</span></h5>
                                                            </>
                                                            :
                                                            <>
                                                                <h5>Solicitor Agent Name:<span>{data?.solicitor_agent_first_name_for_building_and_pest}</span></h5>
                                                                <h5>Solicitor Agent Email:<span>{data?.solicitor_agent_email_for_building_and_pest}</span></h5>
                                                            </>
                                                        }
                                                    </>}
                                                <h5>Have Organised Finanace:<span>{data?.have_organised_finanace_for_building_and_pest}</span></h5>
                                                {data?.have_organised_finanace_for_building_and_pest === 'NO' || data?.have_organised_finanace_for_building_and_pest === 'no' &&
                                                    <h5>Need Broker Recommendation:<span>{data?.like_us_to_reccomend_broker_for_building_and_pest}</span></h5>
                                                }
                                            </>

                                        }
                                        {data?.order_report_type === 'V' &&
                                            <>
                                                <h5>Assets Class Type:<span>{data?.assest_class_type}</span></h5>
                                                {data?.assest_class_type == 'Others' &&
                                                    <h5>Assets Description:<span>{data?.assest_others_type_description}</span></h5>}
                                                <h5>Geographic Location:<span>{data?.valuation_property_type}</span></h5>
                                                <h5>Purpose for Valuation:<span>{data?.purpose_of_valuation ? (data?.purpose_of_valuation).replace(/_/g, ' ') : ""}</span></h5>
                                                {data?.purpose_of_valuation == 'Others' &&
                                                    <h5>Valuation Description:<span>{data?.purpose_of_valuation_description_for_others_option}</span></h5>}
                                                <h5>Valuation Estimate:<span>{data?.estimate_of_valuation}</span></h5>
                                                <h5>Time Frame of Valuation:<span>{data?.time_frame_of_valuation}</span></h5>
                                                <h5>Comapany Name:<span>{data?.company_name}</span></h5>
                                            </>
                                        }
                                        {data?.order_report_type === 'D' &&
                                            <>
                                                <h3>Investment property details</h3>
                                                {/* <h5>Address For Investment Property:<span>{data?.address_for_investment_property_for_depreciation}</span></h5> */}
                                                <h5>Property Type:<span>{data?.property_type_for_depreciation}</span></h5>
                                                <h5>Rental Start Date:<span>{data?.rental_start_date_for_depreciation === '' ? "N/A" : data?.rental_start_date_for_depreciation}</span></h5>
                                                <h5>Purchase Price:<span>${data?.purchase_price_for_depreciation}</span></h5>
                                                {/* <h5>Property Manager Name:<span>{data?.property_manager_name_for_depreciation}</span></h5> */}
                                                <h5>Purchase Date:<span>{data?.purchase_date_for_depreciation}</span></h5>
                                                <h5>Settlement Date:<span>{data?.settlement_date_for_depreciation}</span></h5>
                                                <h5>Construction Completion Date:<span>{data?.construction_completion_date_for_depreciation}</span></h5>
                                                <h5>Construction Cost:<span>${data?.construction_costs_for_depreciation === "" ? " N/A" : data?.construction_costs_for_depreciation}</span></h5>
                                                <h5>Renovations:<span>${data?.renovations_for_depreciation === "" ? "N/A" : data?.renovations_for_depreciation}</span></h5>
                                                <h3>Owners details</h3>
                                                <h5>Owner's Name:<span>{data?.owner_name_for_depreciation}</span></h5>
                                                {data?.owner_other_name_for_depreciation !== "" &&
                                                    <h5>Owner Two's Name:<span>{data?.owner_other_name_for_depreciation === "" ? "N/A" : data?.owner_other_name_for_depreciation}</span></h5>}
                                                <h5>Owner's Email:<span>{data?.owner_email_for_depreciation}</span></h5>
                                                <h5>Owner's Contact:<span>{data?.owner_contact_number_for_depreciation}</span></h5>
                                                <h3>Access details</h3>
                                                <h5>Company Name:<span>{data?.company_name_for_depreciation}</span></h5>
                                                <h5>Property Manager:<span>{data?.property_manager_for_depreciation}</span></h5>
                                                <h5>Company Contact:<span>{data?.company_contact_for_depreciation}</span></h5>
                                                <h3>Accountants details</h3>
                                                <h5>Accountant Name:<span>{data?.name_for_depreciation === "" ? "N/A" : data?.name_for_depreciation}</span></h5>
                                                <h5>Accountant Email:<span>{data?.email_id_for_depreciation === "" ? "N/A" : data?.email_id_for_depreciation}</span></h5>
                                                <h5>Accountant Contact:<span>{data?.contact_number_for_depreciation === "" ? "N/A" : data?.contact_number_for_depreciation}</span></h5>
                                            </>
                                        }
                                        {data?.order_report_type === 'QS' &&
                                            <>

                                                <h5>Message: <span>{data?.message_for_qs_report}</span></h5>
                                            </>
                                        }
                                        {data?.order_report_type === 'DI' &&
                                            <>

                                                <h5>Message: <span>{data?.dilapidation_report?.message_for_dilapidation_report}</span></h5>
                                            </>
                                        }
                                        {data?.order_report_type === 'PIC' &&
                                            <>
                                                <h5>Inspection type:<span>{data?.pool_inspection_report?.inspection_type}</span></h5>
                                                <h5>Message: <span>{data?.pool_inspection_report?.message_for_pool_inspection_report}</span></h5>
                                            </>
                                        }
                                        {data?.order_report_type === 'HI' &&
                                            <>

                                                <h5>Message: <span>{data?.handover_inspection_report?.message_for_handover_inspection_report}</span></h5>
                                            </>
                                        }
                                        {data?.order_report_type === 'SI' &&
                                            <>

                                                <h5>Message: <span>{data?.stage_inspection_report?.message_for_stage_inspection_report}</span></h5>
                                            </>
                                        }
                                        <h5>Report ID:<span>{data?.request_id_no}</span> </h5>
                                        {data?.order_report_type === 'B&P' ?
                                            <h5>Building and Pest Address:<span>{data?.address}</span></h5> :
                                            <h5>Address:<span>{data?.address}</span> </h5>
                                        }
                                        <h5>Report File:<span>{data?.report_file === '' ? 'Pending' : <>Updated <a href={MEDIA_URL + data?.report_file} target='_blank' download><img src={pdfimg} alt="" width="30" /></a></>}</span> </h5>
                                    </div>
                                    : <h4 className="text-center">Loading...</h4>}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );

}

export default ViewReport;
