import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './App.css';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Login from "./components/Admin/Login/Login"
import Header from './components/Common/Header/Header';
import Loader from './components/Common/Loader/loader';
import Sidebar from './components/Common/Sidebar/sidebar';
import { Dashboard } from './components/Dashboard/dashboard';
import Routing from './Routes/Routing';
import { store } from './Services/Store/store';
// import Routing from './Routes/Routing';



function App() {

  // window.Intercom("boot", {
  //   // api_base: "https://api-iam.intercom.io",
  //   app_id: "g1j16afc"
  // });

  // window.Intercom("update");
  
  const selector = useSelector((state) => state)

  // const[screen , set_screen]=useState('dashboard')
  return (
    <div >
      {(selector.is_logged_in === true || selector.is_logged_in === "true") &&
        <>
          {(selector.is_loading === true || selector.is_loading === "true") &&
            <Loader />
          }
          <Header />
          <Sidebar />
        </>
       } 

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
      />
      <Routing />
      {/* {screen!=='login' &&(<><Header/>
                        <Sidebar/> </>)}
                            {screen==='dashboard' && <Dashboard/>}
                            {screen==='login' && <Login/>}
                          */}
    </div>
  );
}

export default App;
