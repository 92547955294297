import { BiEdit } from 'react-icons/bi'
import { BsEye } from 'react-icons/bs'
import { FcSearch } from 'react-icons/fc'
import { useEffect, useState } from 'react'
import { ApiServices, MEDIA_URL } from '../../../APIS/apiservices'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { BsFillCloudUploadFill, BsFillCloudDownloadFill, BsTrash2 } from 'react-icons/bs'
import { isloading } from '../../../Services/Store/action'
import Pagination from "react-js-pagination";
import moment from 'moment'
import DeleteButton from '../../Common/Buttons/DeleteButton'

const StrataReport = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [search, set_search] = useState('')
    const [list, set_list] = useState([]);
    const [page, set_page] = useState(0)
    const [count, set_count] = useState(0)
    const [reload, setReload] = useState(false)
    let selector = useSelector((state) => state)

    useEffect(() => {
        report_list(search, page)
    }, [reload])

    function report_list(search, page) {
        dispatch(isloading(true))
        ApiServices.get_report_list(selector.token, "S", search, page).then((response) => {
            set_list(response.data.data)
            set_count(response.data.result_count)
            dispatch(isloading(false))
        }).catch((error) => {
            dispatch(isloading(false))
        })
    }

    const handlesearch = (event) => {
        if (event.key === 'Enter') {
            report_list(search, page)
        }
    }

    return (
        <>
            <div class="wrapper">
                <div class="content-wrapper">
                    <section class="content-header">
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-6">
                                    <h1>Strata Report</h1>
                                </div>
                                <div class="col-sm-6">
                                    {/* <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">Strata Report</li>
                </ol> */}
                                </div>
                            </div>
                        </div>
                    </section>

                    {!selector.is_loading ?
                        <section class="content">
                            <div>
                                <input className='search-field' type="text" value={search} onChange={(e) => set_search(e.target.value)} placeholder='Search by email,report id and address' onKeyDown={handlesearch} />
                                <FcSearch size={25} style={{ cursor: "pointer", marginLeft: "-34px" }} onClick={() => report_list(search, page)} />
                            </div>
                            <br />
                            {
                                list.length > 0 ?
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Report ID</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Report Type</th>
                                                <th>Report Reason</th>
                                                <th>Address</th>
                                                <th>Date Of Request</th>
                                                <th>Status</th>
                                                <th style={{ minWidth: "105px" }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((data) =>
                                                <tr>
                                                    <td>{data.request_id_no}</td>
                                                    <td>{data.requesting_person_first_name}</td>
                                                    <td>{data.requesting_person_email}</td>
                                                    <td>{data.order_type === 'N' ? 'New' : data.order_type === 'O' ? 'Existing' : ''}</td>
                                                    <td>{data.reason_of_report}</td>
                                                    <td>{data.address}</td>
                                                    <td>{moment(data.createdAt).format("YYYY-MM-DD")}</td>
                                                    <td>{data.report_file == '' ? 'Pending' : 'Updated'}</td>
                                                    <td>
                                                        <p>
                                                            <span className="mr-1">
                                                                <BsEye size={25} style={{ color: "#007bff", cursor: "pointer" }} onClick={() => navigate(`/view_report?id=${data._id}`)} />
                                                                {
                                                                    !data.report_file == '' && data.order_type === 'N' ?
                                                                        <BiEdit size={25} style={{ color: "#007bff", cursor: "pointer" }} onClick={() => navigate(`/upload_report?id=${data._id}`)} />
                                                                        :
                                                                        !data.report_file == '' && data.order_type === 'O' ?
                                                                            <a href={MEDIA_URL + data.report_file} target='_blank' download>
                                                                                <BsFillCloudDownloadFill size={25} style={{ color: "#007bff", cursor: "pointer", marginLeft: "2px" }} />
                                                                            </a> :
                                                                            <BsFillCloudUploadFill size={25} style={{ color: "#007bff", cursor: "pointer", marginLeft: "2px" }} onClick={() => navigate(`/upload_report?id=${data._id}`)} />
                                                                }

                                                                {
                                                                    !data.report_file == '' && data.order_type === 'O' ?
                                                                        <BsFillCloudUploadFill size={25} style={{ color: "#007bff", cursor: "pointer", marginLeft: "2px" }} onClick={() => navigate(`/upload_report?id=${data._id}`)} /> : ''
                                                                }
                                                                <DeleteButton buttonType={"report"} id={data._id} reload={setReload} />
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table> :
                                    <h4 className="text-center">No report found</h4>}
                        </section>
                        :
                        <h4 className="text-center">Loading...</h4>}
                    {count > 0 ?
                        <div className='ml-1 mt-3'>
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={page + 1}
                                itemsCountPerPage={20}
                                totalItemsCount={count}
                                pageRangeDisplayed={5}
                                prevPageText="Previous"
                                nextPageText="Next"
                                onChange={(event) => {
                                    set_page(event - 1)
                                    report_list(search, event - 1)
                                }}
                            />
                        </div>
                        : ''}
                </div>
            </div>
        </>
    );
}
export default StrataReport


