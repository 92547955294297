import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { ApiServices } from '../../../APIS/apiservices';
import { useremail, userimage, username } from '../../../Services/Store/action';
import { dispatchUpdateLoggedIn, dispatchUpdateToken, store } from '../../../Services/Store/store';
// import { ApiServices } from '../../../APIS/apiservices';
import Logo from '../../../images/Logo.png';

const Login = () => {
  let navigate = useNavigate()
  const dispatch = useDispatch();

  const [email, set_email] = useState('');
  const [password, set_password] = useState('');

  const submitLogin = (e) => {
    e.preventDefault()
    var emailRegx = /^[a-zA-Z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,10}(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?$/

    if (email.trim() === "") {
      document.getElementById('email_error').innerHTML = "Please enter email address."
      return false;
    }
    if (!emailRegx.test(email)) {
      document.getElementById('email_error').innerHTML = "Enter valid email address."
      return false;
    }
    if (password.trim() === '') {
      document.getElementById('password_error').innerHTML = "Please enter password"
      document.getElementById('email_error').innerHTML = ""
      return false;
    }
    const data = {
      'email': email,
      'password': password
    }
    document.getElementById('password_error').innerHTML = ""
    ApiServices.AdminLogin(data).then(response => {
      if (response.status === 200) {
        dispatch(useremail(response.data.user.email));
        dispatch(username(response.data.user.name));
        dispatch(userimage(response.data.user.profile_image));
        sessionStorage.setItem('is_logged_in', true);
        sessionStorage.setItem('token', response.data.access_token);
        sessionStorage.setItem('name', response.data.user.name);
        sessionStorage.setItem('email', response.data.user.email);
        sessionStorage.setItem('image', response.data.user.profile_image);
        toast.success('Successfully logged in.');
        dispatchUpdateLoggedIn(true)
        dispatchUpdateToken(response.data.access_token)
        navigate('/dashboard')
      }
    }).catch(err => {
      toast.error(err.response.data.message);
    })
  }

  useEffect(() => {
    dispatchUpdateLoggedIn(false);
  }, [])

 
  return (
    <section className="login-main-div">
      <div className='container LoginScreen'>

        <div class="card card-info LoginFormContainer">
          <div class="card-header formHeader">
            <img src={Logo} alt="" />
            {/* <h3 class="card-title">Login</h3> */}
          </div>
          {
            /* {/.card-header
             form start}  */
          }
          <form class="form-horizontal">
            <div class="card-body">
              <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Email</label>
                <div class="col-sm-9">
                  <input type="email" class="form-control" id="inputEmail3" placeholder='Enter Email Address' onChange={(e) => set_email(e.target.value)} value={email} />
                  <span style={{ color: "red", display: "block", width: "100%" }} id='email_error'></span>
                </div>

              </div>
              <div class="form-group row">
                <label for="inputPassword3" class="col-sm-3 col-form-label">Password</label>
                <div class="col-sm-9">
                  <input type="password" class="form-control" id="inputPassword3" placeholder="Password" onChange={(e) => set_password(e.target.value)} value={password} />
                  <span style={{ color: "red", display: "block", width: "100%" }} id='password_error'></span>
                </div>

              </div>
              <div class="form-group row">
                {/* <div class="offset-sm-3 col-sm-9">
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="exampleCheck2"/>
                        <label class="form-check-label" for="exampleCheck2">Remember me</label>
                      </div>
                    </div> */}
              </div>
            </div>
            {/* /.card-body */}
            <div class="card-footer">
              <button type="submit" class="btn btn-primary" onClick={(e) => submitLogin(e)}>Sign in</button>
              {/* <button type="submit" class="btn btn-default float-right">Cancel</button> */}
            </div>
            {/* /.card-footer */}
          </form>
        </div>
      </div>
    </section>
  );
}

export default Login;
