import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ApiServices, MEDIA_URL } from "../../../APIS/apiservices";
import userimage from "../../../images/userrrr4.jpg";
import { isloading } from "../../../Services/Store/action";
import ReactTable from "react-table"
import { BsEye, BsFillCloudDownloadFill, BsFillCloudUploadFill } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { FcSearch } from "react-icons/fc";
import moment from "moment";
import Pagination from "react-js-pagination";
// import 'react-table/react-table.css'

const Viewuser = () => {
    let selector = useSelector((state) => state)
    const dispatch = useDispatch();
    const [name, set_name] = useState('')
    const [profile, set_profile] = useState('')
    const [email, set_email] = useState('')
    const [phone, set_phone] = useState('')
    const [status, set_status] = useState('')
    const [type, set_type] = useState('')
    const [abn, set_abn] = useState('')
    const [agency, set_agency] = useState('')
    const [address, set_address] = useState('')
    const [search, set_search] = useState('')
    const [page, set_page] = useState(0)
    const [count, set_count] = useState(0)
    const [see_report, set_see_report] = useState('none')
    const [user_reports, set_user_reports] = useState([])

    let navigate = useNavigate()


    const get_user_info = (token, id, search, page) => {
        dispatch(isloading(true))
        ApiServices.get_user_info(token, id, search, page).then(response => {
            if (response.status === 200) {
                set_profile(response.data.data.profile_image)
                set_status(response.data.data.status === "ACT" ? "Active" : "Deactived")
                set_name(response.data.data.name)
                set_email(response.data.data.email)
                set_phone(response.data.data.phone_number)
                set_type(response.data.data.agent_type)
                set_abn(response.data.data.abn_no)
                set_agency(response.data.data.agency)
                set_address(response.data.data.address)
                set_user_reports(response.data.reports)
                set_count(response.data.result_count)
                dispatch(isloading(false))
            }
        }).catch((error) => {
            console.log({ error })
            dispatch(isloading(false))
        })
    }
    const [query, set_query] = useSearchParams()


    function handlesearch() {
        set_page(0)
        var id = query.get('id')
        get_user_info(selector.token, id, search, 0)
    }

    useEffect(() => {
        var id = query.get('id')

        get_user_info(selector.token, id, search, page)
    }, [])
    return (
        <>
            <div class="content-wrapper">
                <section class="content-header">
                    <div class="container-fluid">
                        {!selector.is_loading ?
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="view-user-left">
                                        <img src={profile === '' ? userimage : MEDIA_URL + profile} alt="img"></img>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="user-right-side">
                                        <h5>Name:<span>{name}</span> </h5>
                                        <h5>Email:<span>{email}</span> </h5>
                                        <h5>Phone:<span>{phone}</span> </h5>
                                        <h5>Type:<span>{type === 'R' ? 'Real Estate Agent' : type === 'S' ? 'Strata Repoter' : type === 'B' ? 'Buyer Agent' : type === 'C' ? 'Solicitor' : type === "CO" ? "Conveyancer" : type === "BR" ? "Broker" : type === "A" ? "Accountant" :''}</span> </h5>
                                        <h5>ABN:<span>{abn}</span> </h5>
                                        <h5>Agency:<span>{agency}</span> </h5>
                                        <h5>Address:<span>{address}</span> </h5>
                                        <h5>Status:<span>{status}</span> </h5>
                                    </div>
                                </div>
                            </div>
                            : <h4 className="text-center">Loading...</h4>}
                    </div>

                </section>
                {/* <span className="seereport-bt" onClick={(e) => see_report === 'none' ? set_see_report('block') : set_see_report('none')}>See User Reports</span> */}
                {user_reports.length !== 0 ?
                    <section class="content" >
                        <div>
                            <input className='search-field' type="text" placeholder='Search by report id or address' value={search} onChange={(e) => set_search(e.target.value)} />
                            <FcSearch size={25} style={{ cursor: "pointer", marginLeft: "-34px" }} onClick={(e) => handlesearch(e)} />
                        </div>
                        <br />

                        <table>
                            <thead>
                                <tr>
                                    <th>Report ID</th>
                                    <th>Report Type</th>
                                    <th>Report Status</th>
                                    <th>Address</th>
                                    <th>Date Of Request</th>

                                    <th style={{ minWidth: "105px" }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {user_reports.length !== 0 && user_reports.map((report) =>
                                    <tr>
                                        <td>{report.request_id_no}</td>
                                        <td>{report.order_report_type === "S" ? "Strata report" : report.order_report_type === "D" ? "Depreciation report" : report.report.order_report_type === "B&P" ? "Building & Pests reports" : "Valuation report"}</td>
                                        <td>{report.order_type === "N" ? "New" : "Old"}</td>
                                        <td>{report.address}</td>
                                        <td>{moment(report.createdAt).format("YYYY-MM-DD")}</td>
                                        <td>
                                            <p>
                                                <span className="mr-1">
                                                    <BsEye size={25} style={{ color: "#007bff", cursor: "pointer" }} onClick={() => navigate(`/view_report?id=${report._id}`)} />
                                                    {!report.report_file == '' && report.order_type === 'N' ?
                                                        <BiEdit size={25} style={{ color: "#007bff", cursor: "pointer" }} onClick={() => navigate(`/upload_report?id=${report._id}`)} />
                                                        :
                                                        !report.report_file == '' && report.order_type === 'O' ?
                                                            <a href={MEDIA_URL + report.report_file} target='_blank' download>
                                                                <BsFillCloudDownloadFill size={25} style={{ color: "#007bff", cursor: "pointer", marginLeft: "2px" }} />
                                                            </a> :
                                                            <BsFillCloudUploadFill size={25} style={{ color: "#007bff", cursor: "pointer", marginLeft: "2px" }} onClick={() => navigate(`/upload_report?id=${report._id}`)} />}
                                                    {!report.report_file == '' && report.order_type === 'O' ?
                                                        <BsFillCloudUploadFill size={25} style={{ color: "#007bff", cursor: "pointer", marginLeft: "2px" }} onClick={() => navigate(`/upload_report?id=${report._id}`)} /> : ''}

                                                </span></p>
                                        </td>
                                    </tr>

                                )}


                            </tbody>
                        </table>
                        {user_reports.length !== 0 &&
                            <div className='ml-1 mt-3'>
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={page + 1}
                                    itemsCountPerPage={10}
                                    totalItemsCount={count}
                                    pageRangeDisplayed={5}
                                    prevPageText="Previous"
                                    nextPageText="Next"
                                    onChange={(event) => {
                                        var id = query.get('id')
                                        set_page(event - 1)
                                        get_user_info(selector.token, id, search, event - 1)
                                    }}
                                />
                            </div>}
                    </section>
                    :
                    // <p className="text-center" style={{ fontSize: "20px" }}>No Reports Found</p>

                    ''}
            </div>

        </>

    );
}
export default Viewuser