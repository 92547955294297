import { useEffect, useState } from "react";
// import { Bar } from 'react-chartjs-2';
import { useNavigate } from "react-router-dom";
import { BsEye } from 'react-icons/bs'
import { MdOutlineRealEstateAgent } from 'react-icons/md'
import { RiUserStarFill } from 'react-icons/ri'
import { FaUserTag } from 'react-icons/fa'
import { FaUserTie } from 'react-icons/fa'
import { ApiServices } from "../../APIS/apiservices";
import { useDispatch, useSelector } from "react-redux";
import { isloading } from "../../Services/Store/action";
export const Dashboard = () => {
  let selector = useSelector((state) => state)
  let navigate = useNavigate()
  const dispatch = useDispatch();

  const [real_estate_agents, set_real_estate_agents] = useState('')
  const [strata, set_strata] = useState('')
  const [type, set_type] = useState('');
  const [buyer_agents, set_buyer_agents] = useState('')
  const [solicitors, set_solicitors] = useState('')
  const [conveyancers, set_conveyancers] = useState('')
  const [brokers, set_brokers] = useState('')
  const [accountants, set_accountants] = useState('')
  const [new_user_list, set_new_user_list] = useState([])

  function Agents_data() {

  //   window.Intercom("boot", {
  //     // api_base: "https://api-iam.intercom.io",
  //     app_id: "ze2wcmq2",
  //     // name: "ankit",
  //     // email: "ankit@g.com",
  //     // user_id: "420",
  //   });

  //   console.log(window.Intercom("boot", {
  //     app_id
  //       : "ze2wcmq2",
  //       name: "ankit",
  //     email: "ankit@g.com",
  //     user_id: "420",
  //   }))
  //   window.Intercom("update");
  //  window.Intercom('onUnreadCountChange',function(unreadCount) {
  //     console.log(unreadCount)
  //   })



    dispatch(isloading(true))
    ApiServices.get_agents_data(selector.token, type, '').then(response => {
      if (response.status === 200) {
        set_new_user_list(response.data.data)
        set_real_estate_agents(response.data.counts.real_estate_agent)
        set_strata(response.data.counts.starta_reporter)
        set_buyer_agents(response.data.counts.buyers_agent_count)
        set_solicitors(response.data.counts.solicitor_count)
        set_conveyancers(response.data.counts.conveyancer_count)
        set_brokers(response.data.counts.broker_count)
        set_accountants(response.data.counts.accountant_count)
        dispatch(isloading(false))
      }
    }).catch((error) => {
      console({ error })
      dispatch(isloading(false))
    })
  }
  // const options = {
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           beginAtZero: true,
  //         },
  //       },
  //     ],
  //   },
  //   plugins: {

  //     legend: {
  //       display: false,
  //       // hidden:true 
  //       // onclick:(e)=>{
  //       // 	type.current('doughnut')
  //       // }
  //     }
  //   }
  // };

  // var temp = {
  //   labels: ['Real State Agent', 'Strata Reporter', 'Buyers Agent', 'Solicitors'],

  //   datasets: [
  //     {
  //       label: 'Propti Admin',
  //       data: [real_estate_agents, strata, buyer_agents, solicitors],
  //       backgroundColor: ['rgb(158, 188, 245)', 'rgb(130, 43, 43)', 'rgb(46, 68, 133)', 'rgb(12, 194, 170)']
  //     }
  //   ]
  // }

  const to_dashboard = (e) => {
    e.preventDefault()
    navigate('/dashboard')
  }
  useEffect(() => {
    Agents_data();
  }, [])
  // console.log(counts);
  return (
    <>

      <div>
        {/* <div className="preloader flex-column justify-content-center align-items-center">
          <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60" />
        </div> */}

        <div class="content-wrapper">
          <div class="content-header">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-sm-6">
                  <h1 class="m-0">Dashboard</h1>
                </div>
                <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a onClick={(e) => to_dashboard(e)}>Home</a></li>
                    <li class="breadcrumb-item active">Dashboard </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>


          {/* <div class="container-fluid"> */}
          <section className="content">
            <div className="container-fluid">
              <div class="row">
                <div class="col-lg-4 col-6">
                  <div class="small-box bg-info">
                    <div class="inner">
                      <h3>{real_estate_agents}</h3>

                      <p>Real Estate Agent</p>
                    </div>
                    <div class="icon">
                      {/* <i class="ion ion-bag"></i> */}
                      <MdOutlineRealEstateAgent />
                    </div>
                    <a class="small-box-footer" style={{ cursor: "pointer" }} onClick={() => navigate('/real_estate_agent')}>More info <i class="fas fa-arrow-circle-right"></i></a>
                  </div>
                </div>
                {/* <div class="col-lg-3 col-6">
                  <div class="small-box bg-success">
                    <div class="inner">
                      <h3>{strata}</h3>

                      <p>Strata Reporter</p>
                    </div>
                    <div class="icon">
                      <RiUserStarFill />
                    </div>
                    <a class="small-box-footer" style={{ cursor: "pointer" }} onClick={() => navigate('/strata_reporter')}>More info <i class="fas fa-arrow-circle-right"></i></a>
                  </div>
                </div> */}
                <div class="col-lg-4 col-6">
                  <div class="small-box bg-warning">
                    <div class="inner">
                      <h3>{buyer_agents}</h3>

                      <p>Buyers Agent</p>
                    </div>
                    <div class="icon">
                      <FaUserTag />
                    </div>
                    <a class="small-box-footer" style={{ cursor: "pointer" }} onClick={() => navigate('/buyers_agent')}>More info <i class="fas fa-arrow-circle-right"></i></a>
                  </div>
                </div>
                <div class="col-lg-4 col-6">
                  <div class="small-box bg-danger">
                    <div class="inner">
                      <h3>{solicitors}</h3>

                      <p>Solicitors</p>
                    </div>
                    <div class="icon">
                      {/* <i class="ion ion-pie-graph"></i> */}
                      <FaUserTie />
                    </div>
                    <a class="small-box-footer" style={{ cursor: "pointer" }} onClick={() => navigate('/solicitors')}>More info <i class="fas fa-arrow-circle-right"></i></a>
                  </div>
                </div>

                <div class="col-lg-4 col-6">
                  <div class="small-box bg-danger">
                    <div class="inner">
                      <h3>{conveyancers}</h3>

                      <p>Conveyancers</p>
                    </div>
                    <div class="icon">
                      {/* <i class="ion ion-pie-graph"></i> */}
                      <FaUserTie />
                    </div>
                    <a class="small-box-footer" style={{ cursor: "pointer" }} onClick={() => navigate('/conveyancers')}>More info <i class="fas fa-arrow-circle-right"></i></a>
                  </div>
                </div>

                <div class="col-lg-4 col-6">
                  <div class="small-box bg-warning">
                    <div class="inner">
                      <h3>{brokers}</h3>

                      <p>Brokers</p>
                    </div>
                    <div class="icon">
                      {/* <i class="ion ion-pie-graph"></i> */}
                      <FaUserTie />
                    </div>
                    <a class="small-box-footer" style={{ cursor: "pointer" }} onClick={() => navigate('/brokers')}>More info <i class="fas fa-arrow-circle-right"></i></a>
                  </div>
                </div>

                <div class="col-lg-4 col-6">
                  <div class="small-box bg-info">
                    <div class="inner">
                      <h3>{accountants}</h3>

                      <p>Accountants</p>
                    </div>
                    <div class="icon">
                      {/* <i class="ion ion-pie-graph"></i> */}
                      <FaUserTie />
                    </div>
                    <a class="small-box-footer" style={{ cursor: "pointer" }} onClick={() => navigate('/accountants')}>More info <i class="fas fa-arrow-circle-right"></i></a>
                  </div>
                </div>

              </div>



              <div class="row mt-4">
                <section class="col-lg-12 connectedSortable">
                  <div class="row">
                    <div className="col-md-12">
                      <div className="user-box-list">
                        <h5>Recently Joined Users</h5>
                        <table>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Phone Number</th>
                              <th>Type</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {new_user_list.length > 0 && new_user_list.filter(user => user.agent_type !== "S").map((user, index) =>
                              index < 5 ?
                                <tr>
                                  <td>{user.name}</td>
                                  <td>{user.email}</td>
                                  <td>{user.phone_number}</td>
                                  <td>{user.agent_type === "R" ? "Real Estate Agent" : user.agent_type === "B" ? "Buyers Agent" : user.agent_type === "C" ? "Solicitor" : user.agent_type ==="CO" ? "Conveyancer": user.agent_type === "BR" ? "Broker" : user.agent_type === "A" ? "Accountant" : ''}</td>
                                  <td>
                                    <p><span className="mr-2"><BsEye size={25} style={{ color: "#007bff", cursor: "pointer" }} onClick={user.agent_type === 'R' ? () => navigate('/real_estate_agent') : user.agent_type === 'S' ? () => navigate('/strata_reporter') : user.agent_type === 'B' ? () => navigate('/buyers_agent') : user.agent_type === 'C' ? () => navigate('/solicitors') : user.agent_type === 'CO' ? () => navigate('/conveyancers') : user.agent_type === 'BR' ? () => navigate('/brokers') : user.agent_type === 'A' ? () => navigate('/accountants') : ''} /></span></p></td>
                                </tr>

                                : ''

                            )}


                          </tbody>
                        </table>
                        {/* <p className="view-all-bt" onClick={() => navigate('/real_estate_agent')}>View All</p> */}

                      </div>

                    </div>
                  </div>
                </section>
                {/* <section class="col-lg-6 connectedSortable">
                  <Bar data={temp} options={options} />
                </section> */}

              </div>
            </div>
          </section>

          {/* <aside class="col-lg-12">
					<div class="white_box total_sold">
						<h4>Drishti Gem's </h4>
						<div id="user_customer_operator" style={{ minWidth: "310px", margin: "0 auto" }}>
							<div className="row">
								<div className="col-md-12">
									
								</div>
							</div>

						</div>
					</div>
				</aside> */}

        </div>



      </div>
    </>
  );
}