import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ApiServices, MEDIA_URL } from "../../../APIS/apiservices";
import pdfimg from '../../../images/pdfimg.png';
import { isloading } from "../../../Services/Store/action";

const ViewPayment = () => {

    let selector = useSelector((state) => state);
    const [query, set_query] = useSearchParams();
    const id = query.get('id')
    const [data, set_data] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        get_detail()
    }, [])

    function get_detail() {
        dispatch(isloading(true))
        ApiServices.get_report_detail(selector.token, id).then((response) => {
            set_data(response.data.data)
            dispatch(isloading(false))
        }).catch((error) => {
            console.log({ error })
            dispatch(isloading(false))
        })
    }

    return (
        <>
            <div class="content-wrapper">
                <section class="content-header">
                    <h3 style={{ marginLeft: '7px' }}>Payment Details</h3>
                    <div class="container-fluid">
                        <div className="row">
                            <div className="col-md-10">
                                {!selector.is_loading ?
                                    <div className="user-right-side">
                                        <h5>Name: <span>{data.requesting_person_first_name + data.requesting_person_last_name}</span></h5>
                                        <h5>Email: <span>{data.requesting_person_email}</span></h5>
                                        <h5>Phone Number: <span>{data.requesting_person_mobile_number}</span></h5>
                                        <h5>Transaction ID: <span>{data.transaction_id}</span></h5>
                                        <h5>Report Price: <span>${data.report_price}</span></h5>
                                        <h5>Request ID No.: <span>{data.request_id_no}</span></h5>
                                        <h5>Inspection Date: <span>{data.inspection_date}</span></h5>
                                        <h5>Address: <span>{data.address}</span></h5>
                                    </div>
                                    : <h4 className="text-center">Loading...</h4>}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );

}

export default ViewPayment;
