import "./action";
import { UPDATE_EMAIL, UPDATE_Is_Logged_In, UPDATE_PASSWORD, IS_LOADING, USER_EMAIL, USER_IMAGE, USER_NAME } from "./action";



const initaiState = {
  token: sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '',
  is_logged_in: sessionStorage.getItem('is_logged_in') ? sessionStorage.getItem('is_logged_in') : false,
  MEDIA_URL: 'http://198.211.110.165:4001',
  is_loading: false,
  user_image: sessionStorage.getItem('image') ? sessionStorage.getItem('image') : "",
  user_name: sessionStorage.getItem('name') ? sessionStorage.getItem('name') : "",
  user_email: sessionStorage.getItem('email') ? sessionStorage.getItem('email') : ""
}


// console.log( window.Intercom('onShow', function(e) { return babel;})); 

export const AppReducer = (state = initaiState, action) => {
  let tempStore = { ...state };
  switch (action.type) {
    case UPDATE_EMAIL: {
      tempStore.email = action.payload
      return tempStore;
    }
    case UPDATE_PASSWORD: {
      tempStore.password = action.payload
      return tempStore;
    }
    case UPDATE_Is_Logged_In: {
      tempStore.is_logged_in = action.payload
      return tempStore;
    }
    case "UPDATE_TOKEN": {
      tempStore.token = action.payload
      return tempStore;
    }
    case IS_LOADING: {
      return {
        ...state,
        is_loading: action.payload
      }
    }
    case USER_EMAIL: {
      return {
        ...state,
        user_email: action.payload
      }
    }
    case USER_IMAGE: {
      return {
        ...state,
        user_image: action.payload
      }
    }
    case USER_NAME: {
      return {
        ...state,
        user_name: action.payload
      }
    }
    default: {
      return state;
    }
  }
}