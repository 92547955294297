import { createStore } from "redux"
import { AppReducer } from "./reducer";
import * as Actions from "./action";

// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
export const store = createStore(AppReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

export const dispatchUpdateEmail = (email) => {
    store.dispatch(Actions.updateEmail(email));
}

export const dispatchUpdatePassword = (password) => {
    store.dispatch(Actions.updatePassword(password));
}

export const dispatchUpdateLoggedIn = (is_logged_in) => {
    store.dispatch(Actions.updateIsLoggedIn(is_logged_in))
}

export const dispatchUpdateToken = (token) => {
    store.dispatch(Actions.updateToken(token))
}