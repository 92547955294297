import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./editor";
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { ApiServices, MEDIA_URL } from "../../../APIS/apiservices";
import { isloading } from "../../../Services/Store/action";
import { BlogEditor } from "./blogEditor";

const Blogs = () => {

    let selector = useSelector((state) => state)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [Category_list, set_category_list] = useState([])
    const [blog_img, set_blog_img] = useState('')
    const [blog_img_url, set_blog_img_url] = useState('')
    const [title, set_title] = useState('')
    const [metaTitle, setMetaTitle] = useState('')
    const [metaDescription, setMetaDescription] = useState('')
    const [focusedKeyword, setFocusedKeyword] = useState("")
    const [content, set_content] = useState('')
    const [category, set_category] = useState('')
    const [query, set_query] = useSearchParams()
    const [id, set_id] = useState('')
    const [status, set_status] = useState(false)
    const [dis_title, set_dis_title] = useState(false)
    const [metaStatus, setMetaStatus]=useState(false)
    const [altTag, setAltTag]=useState("")

    function get_categories() {
        ApiServices.get_category_list(selector.token).then(response => {
            if (response.status === 200) {
                set_category_list(response.data.data)
            }
        }).catch((error) => {
            console.log({ error })
        })
    }

    function handleImage(e) {
        let sFile = e.target.files[0]
        var fName = sFile.name
        var regex = new RegExp("(.*?)\.(jpg|jpeg|png)$");
        if (!(regex.test(fName))) {
            Swal.fire(
                '',
                'Please select valid image file.',
                'warning'
            )
            return;
        }
        set_blog_img_url(URL.createObjectURL(e.target.files[0]))
        set_blog_img(e.target.files[0])
    }

    function handleSubmit(e) {
        toast.dismiss()
        if (category === '') {
            toast.error('Please choose category.')
            return false;
        }
        if (blog_img === "") {
            toast.error('Select image to upload.')
            return false;
        }
        if (title === '') {
            toast.error('Please enter the title.')
            return false;
        }
        if (content === "") {
            toast.error('Please enter the content.')
            return false;
        }
        if (metaTitle === "") {
            toast.error('Please enter meta title.')
            return false;
        }
        if (metaDescription === "") {
            toast.error('Please enter meta description.')
            return false;
        }
        if (focusedKeyword === "") {
            toast.error('Please enter focused keyword.')
            return false;
        }
        if(altTag === ""){
            toast.error('Please enter alt tag.')
            return false;
        }
        const data = new FormData()
        data.append('article_image', blog_img)
        data.append('category', category)
        data.append('article_title', title)
        data.append('article_description', content)
        data.append('meta_title', metaTitle)
        data.append('meta_description', metaDescription)
        data.append('focused_keyword', focusedKeyword)
        data.append("alt_tag",altTag)
        console.log("data---->",data)

        dispatch(isloading(true))
        ApiServices.add_blog(selector.token, data).then(response => {
            if (response.status === 200) {
                dispatch(isloading(false))
                navigate('/articles')
                toast.success("Successfully Added.")
            }
        }).catch(err => {
            dispatch(isloading(false))
            toast.error(err.response.data.message)
        })
    }

    function handleUpdate(e) {
        toast.dismiss()
        if (category === '') {
            toast.error('Please choose category.')
            return false;
        }
        if (title === '') {
            toast.error('Please enter the title.')
            return false;
        }
        if (content === "") {
            toast.error('Please enter the content.')
            return false;
        }
        const data = new FormData()
        data.append('article_image', blog_img)
        data.append('category', category)
        data.append('article_title', title)
        data.append('article_description', content)
        dispatch(isloading(false))
        dispatch(isloading(true))
        ApiServices.update_article(selector.token, id, data).then(response => {
            if (response.status === 200) {
                dispatch(isloading(false))
                toast.success("Article Updated Successfully.")
                navigate('/articles')
            }
        }).catch(err => {
            dispatch(isloading(false))
            toast.error(err.response.data.message)
        })
    }

    useEffect(() => {
        setMetaStatus(false)
        set_dis_title(false)
        if (query != '') {
            dispatch(isloading(true))
            set_id(query.get('id'))
            ApiServices.article_detail(selector.token, query.get('slug')).then((response) => {
                console.log("res-------",response)
                set_blog_img_url(MEDIA_URL + response.data.data.article_image)
                set_category(response.data.data.category._id)
                set_title(response.data.data.article_title)
                set_content(response.data.data.article_description)
                setMetaTitle(response.data.data.meta_title)
                setMetaDescription(response.data.data.meta_description)
                setFocusedKeyword(response.data.data.focused_keyword)
                setAltTag(response.data.data?.alt_tag)
                dispatch(isloading(false))
                set_status(true)
                set_dis_title(true)
                setMetaStatus(true)
            }).catch((error) => {
                console.log({ error })
                dispatch(isloading(false))
            })
        }
        else {
            set_blog_img('')
            set_blog_img_url('')
            set_title('')
            set_content('')
            set_category('')
            setMetaTitle('')
            setMetaDescription('')
            setFocusedKeyword('')
            setAltTag("")
            set_status(false)
        }
        get_categories()
    }, [query])

    return (
        <div class="wrapper">
            <div class="content-wrapper">
                <section class="content-header">
                    {!selector.is_loading ?
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-12">
                                    <h4 className="mb-3">Articles/Blogs</h4>
                                    <div className="profile_box">
                                        <img src={blog_img_url == '' ? require('../../../images/userrrr4.jpg') : blog_img_url} height="160" width="160" className="img-circle" alt="User Image" />
                                        <input onChange={(e) => handleImage(e)} type="file" disabled={status} accept="image/png, image/gif, image/jpeg" />
                                    </div>
                                    <div className="select_list">
                                        {/* <label>Category</label>
                                        <select className="mr-2" onChange={(e) => set_category(e.target.value)} value={category} disabled={status}>
                                            <option value=''>--select--</option>
                                            {Category_list.map((category) =>
                                                <option value={category._id}>{category.category_name}</option>
                                            )}

                                        </select> */}
                                        {query != '' && status === true ?
                                            <Button onClick={() => set_status(!status)}>Edit</Button>
                                            : ""}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="blog_form mt-4" style={{ display: "block" }}>
                                                <div>
                                                    <label for="title">Title</label>
                                                    <input type="text" id="title" maxLength={100} placeholder="Enter Title" style={{ fontWeight: 'bold' }} onChange={(e) => set_title(e.target.value)} value={title} disabled={dis_title}></input>
                                                </div>
                                                <div className="select-type  mt-3">
                                                    <div className="select_list">
                                                        <label>Category</label>
                                                        <select className="mr-2" onChange={(e) => set_category(e.target.value)} value={category} disabled={status}>
                                                            <option value=''>--select--</option>
                                                            {Category_list.map((category) =>
                                                                <option value={category._id}>{category.category_name}</option>
                                                            )}

                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="mt-2 new-ql">
                                                    <label for="title">Content</label>
                                                    {/* <textarea className="input100"  placeholder="Enter Description Here." rows={8} onChange={(e) => set_content(e.target.value)} value={content} disabled={status}> */}

                                                    {/* </textarea> */}

                                                    <BlogEditor />
                                                    <ReactQuill
                                                        readOnly={status}
                                                        theme="snow"
                                                        value={content}
                                                        onChange={(e) => set_content(e)}
                                                        placeholder={"Enter Default Terms..."}
                                                        modules={modules}
                                                        formats={formats}
                                                    />
                                                </div>
                                                <div>
                                                    <label for="title">Meta Title</label>
                                                    <input type="text" id="title" maxLength={60} placeholder="Enter Meta Title" style={{ fontWeight: 'bold' }} onChange={(e) => setMetaTitle(e.target.value)} value={metaTitle} disabled={metaStatus}></input>
                                                </div>
                                                <div>
                                                    <label for="title">Meta Description</label>
                                                    <input type="text" id="title" maxLength={180} placeholder="Enter Meta Description" style={{ fontWeight: 'bold' }} onChange={(e) => setMetaDescription(e.target.value)} value={metaDescription} disabled={metaStatus}></input>
                                                </div>
                                                <div>
                                                    <label for="title">Focused Keyword</label>
                                                    <input type="text" id="title" maxLength={100} placeholder="Enter Focused Keyword" style={{ fontWeight: 'bold' }} onChange={(e) => setFocusedKeyword(e.target.value)} value={focusedKeyword} disabled={metaStatus}></input>
                                                </div>
                                                <div>
                                                    <label for="title">Alt tag</label>
                                                    <input type="text" id="title" maxLength={60} placeholder="Enter Alt tag" style={{ fontWeight: 'bold' }} onChange={(e) => setAltTag(e.target.value)} value={altTag} disabled={metaStatus}></input>
                                                </div>

                                                {query == '' ?
                                                    <button type="submit" onClick={(e) => handleSubmit(e)}>Submit</button>
                                                    :
                                                    status == false ? <button type="submit" onClick={(e) => handleUpdate(e)} disabled={status}>Update</button> : ''
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        :
                        <h4 className="text-center">Loading...</h4>}
                </section>
            </div>
        </div>
    );

}

export default Blogs;
