import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ApiServices, MEDIA_URL } from "../../../APIS/apiservices";
import pdfimg from '../../../images/pdfimg.png';
import { isloading } from "../../../Services/Store/action";

const ViewInsurance = () => {

    let selector = useSelector((state) => state);
    const [query, set_query] = useSearchParams();
    const id = query.get('id')
    const [data, set_data] = useState([]);
    const dispatch = useDispatch();


    function get_detail() {
        dispatch(isloading(true))
        ApiServices.get_insurance_details(selector.token, id).then((response) => {
            set_data(response.data.data)
            dispatch(isloading(false))
        }).catch((error) => {
            console.log({ error })
            dispatch(isloading(false))
        })
    }

    useEffect(() => {
        get_detail()
    }, [])

    return (
        <>
            <div class="content-wrapper">
                <section class="content-header">
                    {data.length !== 0 ?
                        <>
                            <h3 style={{ marginLeft: '7px' }}>{data.insurance_type === "S" ? "Strata Insurance" : "Property Insurance"}</h3>
                            <div class="container-fluid">
                                <div className="row">
                                    <div className="col-md-10">
                                        {!selector.is_loading ?
                                            <div className="user-right-side">
                                                <h5>Name:<span></span>{data.name}</h5>
                                                <h5>Email:<span></span>{data.email_id}</h5>
                                                <h5>Phone:<span></span>{data.phone_number}</h5>
                                                <h5>{data.insurance_type === "S" ? "Building Replacement Costs" : "Home Replacement Value"}:<span></span>{data.insurance_type === "S" ? '$' + data.building_replacement_costs : '$' + data.home_replacement_value}</h5>
                                                <h5>{data.insurance_type === "S" ? "Common Area Content Value" : "valuable Article Over Price"}:<span></span>{data.insurance_type === "S" ? '$' + data.common_area_content_value : data.valuable_article_over_price}</h5>
                                                <h5>{data.insurance_type === "S" ? "Strata Number" : "Contents Value"}:<span></span>{data.insurance_type === "S" ? data.strata_number : '$' + data.contents_value}</h5>
                                                <h5>{data.insurance_type === "S" ? "Address To Inspect" : "Address"}:<span></span>{data.address}</h5>
                                                <h5>Current Insurer:<span></span>{data.current_insurer}</h5>


                                            </div>
                                            : <h4 className="text-center">Loading...</h4>}
                                    </div>
                                </div>
                            </div>
                        </>

                        : <h4>No Data Found</h4>}


                </section>
            </div>
        </>
    );

}

export default ViewInsurance;
