import React from 'react'

const PageNotFound = () => {
    return (
        <div id="wrapper">
            <div className='not-found text-center'>
                <img src="https://i.imgur.com/qIufhof.png" width="500"/>
                <div id="info">
                    <h3>This page could not be found</h3>
                </div>
            </div>
        </div >
    )
}

export default PageNotFound