import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import 'react-quill/dist/quill.snow.css';

import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { ApiServices } from "../../../APIS/apiservices";
import validator from "validator";
import { isloading } from "../../../Services/Store/action";
import MobileInput from "../../Common/MobileInput"

const AddCompany = () => {
    let navigate = useNavigate()
    let selector = useSelector((state) => state)
    const dispatch = useDispatch();
    const Initialstate = {
        company_name: "",
        contact_name:"",
        company_number: "",
        company_email: "",
        bank_account: "",
        bsb: "",
        account_name: "",
         }
    const InitialstateErr = {
        company_name: "",
        contact_name:"",
        company_number: "",
        company_email: "",
        bank_account: "",
        bsb: "",
        account_name: "",
    }
      const [formData, setFormData] = useState(Initialstate)
      const [formDataErr, setFormDataErr] = useState(InitialstateErr)
  
      const [query, set_query] = useSearchParams()
      const [company_id, setcompanyid] = useState()

      const id = query.get('id')  
      const validateMobile = (e) => {
        var mobile = e ? e : "";
    
        if (validator.isMobilePhone(mobile, "any")) {
          setFormData({
            ...formData,
            company_number: e,
          });
        } else {
            setFormDataErr({
                ...formDataErr,
                company_number: "Phone number not valid.",
              });
            
        }
      }; 
      useEffect(() => {
        console.log("=====query===",id);
        setcompanyid(id)
        if (id) {
            setcompanyid(id)
           // set_id(query.get('id'))
            ApiServices.get_companyDetail(selector.token, id).then((response) => {
                // console.log("=========uuuuuu====",response.data.specificCompany)
                formData.company_name = response.data.specificCompany.company_name
                formData.contact_name = response.data.specificCompany.contact_name
                formData.company_number = response.data.specificCompany.company_number
                formData.company_email = response.data.specificCompany.company_email
                formData.bank_account = response.data.specificCompany.bank_account
                formData.bsb = response.data.specificCompany.bsb
                formData.account_name = response.data.specificCompany.account_name
                // console.log("=========uuuuuu====",response.data.specificCompany)
                setFormData({...formData,  company_name :response.data.specificCompany.company_name
                    ,contact_name : response.data.specificCompany.contact_name,
                   contact_number :response.data.specificCompany.contact_number,
                    company_email : response.data.specificCompany.company_email,
                    bank_account : response.data.specificCompany.bank_account,
                    bsb : response.data.specificCompany.bsb,account_name : response.data.specificCompany.account_name})
            }).catch((error) => {
                console.log({ error })
                dispatch(isloading(false))
            })

        }

    },[id])
      const validatecompany = ()=>{
        
        if(formData.company_name.trim()==""){
            setFormDataErr({...formDataErr,company_name: "Please enter company name",})
            return false;
        }else if(formData.contact_name.trim()==""){
            setFormDataErr({...formDataErr,contact_name: "Please enter contact name",company_name:""})
            return false;
        }else if(formData.company_number.trim()==""){
            setFormDataErr({...formDataErr,company_number: "Please enter company number",contact_name:""})
            return false;
        }else if(formData.company_email.trim()==""){
            setFormDataErr({...formDataErr,company_email: "Please enter company email",company_number:"",contact_name:"",company_name:""})
            return false;
        }else if (!validator.isEmail(formData.company_email)) {
            setFormDataErr({...formDataErr,company_email: "Please enter valid company email",company_number:"",company_name:"",contact_name:""})
            return false;
        
     } else if(formData.bank_account.trim()==""){
            setFormDataErr({...formDataErr,bank_account: "Please enter bank account",company_email:"",company_number:"",company_name:"",contact_name:""})
            return false;
        } else if(formData.bsb.trim()==""){
            setFormDataErr({...formDataErr,bsb: "Please enter bsb",bank_account:"",company_email:"",company_number:"",company_name:"",contact_name:""})
            return false;
        }else if(formData.account_name.trim()==""){
            setFormDataErr({...formDataErr,account_name: "Please enter account bame",account_name:"",bank_account:"",company_email:"",company_number:"",company_name:"",contact_name:"",bsb:""})
            return false;
        }else{
            return true; 
        }
      }
 const submitform =()=>{
     let validate = validatecompany();
     if(validate){
        dispatch(isloading(true))
        console.log(company_id)
        if(company_id){
          ApiServices.update_company(selector.token,formData,company_id).then(response => {
            Swal.fire('',
            'Updated Successfully',
            'success').then((result) => {
                if (result.isConfirmed) {
                    navigate("/companyList")
                    // history.push('/insights')
                }
                setTimeout(() => {
                    navigate("/companyList")
                }, 3000)
            })
    
                
            
        }).catch((error) => {
            dispatch(isloading(false))
            console.log({ error })
        })
    }else{
    ApiServices.add_company(selector.token,formData).then(response => {
        Swal.fire('',
        'Added Successfully',
        'success').then((result) => {
            if (result.isConfirmed) {
                navigate("/companyList")
                // history.push('/insights')
            }
            setTimeout(() => {
                navigate("/companyList")
            }, 3000)
        })

            
        
    }).catch((error) => {
        dispatch(isloading(false))
        console.log({ error })
    })
}
}
    
 }
       
  

  
 

    return (
        <div class="wrapper">
            <div class="content-wrapper">
                <section class="content-header">
                   
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-12">
                                    <h4 className="mb-3">{company_id?"Update Company":"Add Company"}</h4>
                               
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="blog_form mt-4" style={{ display: "block" }}>
                                                <div>
                                                    <label for="title">Company  Name*</label>
                                                    <input type="text" value={formData.company_name} id="name" maxLength={40} placeholder="Enter Name" style={{ fontWeight: 'bold' }} onChange={(e) => { setFormData({...formData,company_name: e.target.value}); }}></input>
                                                    <span className="error">{formDataErr.company_name}</span>
                                                </div>
                                                <div>
                                                    <label for="title">Contact  Name*</label>
                                                    <input type="text" value={formData.contact_name}  id="contact" maxLength={40} placeholder="Enter Name" style={{ fontWeight: 'bold' }} onChange={(e) => { setFormData({...formData,contact_name: e.target.value,}); }}></input>
                                                    <span className="error">{formDataErr.contact_name}</span>
                                                </div>
                                                <div>
                                                    <label for="title">Company Number*</label>
                                                    <MobileInput
                                value={formData.company_number}
                                onChange={(e) => validateMobile(e)}
                              />
                                                    {/* <input type="number" value={parseInt(formData.company_number)}  id="number" maxLength={100} onChange={(e) => {  setFormData({...formData,company_number: e.target.value,}); }} placeholder="Enter Company Number" style={{ fontWeight: 'bold' }}></input> */}
                                                    <span className="error">{formDataErr.company_number}</span>
                                                </div>
                                                <div>
                                                    <label for="title">Company Email*</label>
                                                    <input type="text" value={formData.company_email}  id="email" onChange={(e) => { setFormData({...formData,company_email: e.target.value, });
                                                        }} maxLength={50} placeholder="Enter Company Email" style={{ fontWeight: 'bold' }}></input>
                                                         <span className="error">{formDataErr.company_email}</span>
                                                </div>
                                                <div>
                                                    <label for="title">Bank Account*</label>
                                                    <input type="text"  value={formData.bank_account}  onChange={(e) => {setFormData({...formData,bank_account: e.target.value }); }} id="bank" maxLength={20} placeholder="Enter Bank Account" style={{ fontWeight: 'bold' }}></input>
                                                    <span className="error">{formDataErr.bank_account}</span>
                                                </div>
                                                <div>
                                                    <label for="title">BSB*</label>
                                                    <input type="text" value={formData.bsb} onChange={(e) => { setFormData({ ...formData,  bsb: e.target.value, }); }} id="bsb" maxLength={40} placeholder="Enter BSB" style={{ fontWeight: 'bold' }}></input>
                                                    <span className="error">{formDataErr.bsb}</span>
                                                </div>
                                                <div>
                                                    <label for="title">Account Name*</label>
                                                    <input type="text" value={formData.account_name} onChange={(e) => {setFormData({...formData,account_name: e.target.value, }); }} id="account" maxLength={40} placeholder="Enter Account Name" style={{ fontWeight: 'bold' }}></input>
                                                    <span className="error">{formDataErr.account_name}</span>
                                                </div>
                                                <button type="button" onClick={() => submitform()}>Submit</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                     
                </section>
            </div>
        </div>
    );

}

export default AddCompany;
