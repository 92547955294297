import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ApiServices, MEDIA_URL } from "../../../APIS/apiservices";
import pdfimg from '../../../images/pdfimg.png';
import { isloading } from "../../../Services/Store/action";
import moment from "moment";
const PropertyDetail = () => {

    let selector = useSelector((state) => state);
    const [query, set_query] = useSearchParams();
    const id = query.get('id')
    const [data, set_data] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        get_detail()
    }, [])

    function get_detail() {
        dispatch(isloading(true))
        ApiServices.get_propertyDetail(selector.token, id).then((response) => {
            set_data(response.data.specificProperty)
            dispatch(isloading(false))
        }).catch((error) => {
            console.log({ error })
            dispatch(isloading(false))
        })
    }

    return (
        <>
            <div class="content-wrapper">
                <section class="content-header">
                    <h3 style={{ marginLeft: '7px' }}>Report Details</h3>
                    <div class="container-fluid">
                        <div className="row">
                            <div className="col-md-10">
                            <div className="user-right-side">
                            <h5>Type:<span>{data.report_type}</span> </h5>
                                {data.report_type=="SELL"?
                                     <>
                                    {/* <h5>Company Name from which hear about us:<span>{data.company_name==""?data.company_id.company_name:data.company_name}</span> </h5> */}
                                    <h5>Property Type:<span>{data.property_type}</span> </h5>
                                    {data.property_type!="LAND"?
                                    <h5>Bed Rooms:<span>{data.bed_rooms}</span> </h5>
                                    :null}
                                    <h5>Plan to sell:<span>{data.plan_to_sell} Months</span> </h5>
                                    <h5>purchase another property:<span>{data.purchase_another_property}</span> </h5>
                                   
                                    </>
                                    :
                                    null}
                                    {data.report_type=="BUY"?
                                      <h5>Plan to Buy:<span>{data.plan_to_sell} Months</span> </h5>
                                      :null}
                                      {data.report_type=="REFINANCE"?
                                      <>
                                      <h5>Looking  to refinance:<span>
                                      {
                                      data.refinance.replace('_',' ')
                                      }</span> </h5>
                                      <h5>Considering refinance:<span>{data.plan_to_sell} Months </span> </h5>
                                      </>
                                      :null}
                                    <h5>Full Name:<span>{data.full_name}</span> </h5>
                                    <h5>Email:<span>{data.email}</span> </h5>
                                    <h5>Mobile:<span>{data.contact_number}</span> </h5>
                                    <h5>Created At:<span>{moment(data.createdAt).format("YYYY-MM-DD")}
                                      </span> </h5>
                                    {data?.address && <h5>Address:<span>{data?.address}</span></h5>}
                                    
                                    </div>

                                </div>
                                </div>
                                </div>
                                </section>
                                </div>

</>


                                )



    }
    export default PropertyDetail