import { FcSearch } from 'react-icons/fc'

import { HiTrash } from 'react-icons/hi'
import { BiEdit } from 'react-icons/bi'
import { BsEye } from 'react-icons/bs'
import { ApiServices } from '../../../APIS/apiservices'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Swal from 'sweetalert2'
import { isloading } from '../../../Services/Store/action'
import Pagination from "react-js-pagination";
import moment from 'moment'
import DeleteButton from '../../Common/Buttons/DeleteButton'

const Brokers = () => {

    let navigate = useNavigate()
    let selector = useSelector((state) => state)
    const [search, set_search] = useState('')
    const dispatch = useDispatch();
    const [page, set_page] = useState(0)
    const [count, set_count] = useState(0)
    const [brokers_list, set_brokers_list] = useState([])
    const [reload, setReload] = useState(false)

    useEffect(() => {
        get_strata_reporter(search, page);
    }, [reload])

    const get_strata_reporter = (search, page) => {
        dispatch(isloading(true))
        ApiServices.get_agents_data(selector.token, "BR", search, page).then(response => {
            if (response.status === 200) {
                set_count(response.data.result_count)
                set_brokers_list(response.data.data)
                dispatch(isloading(false))
            }
        }).catch((error) => {
            console.log({ error })
            dispatch(isloading(false))
        })
    }
    const view_user = (e, id) => {
        navigate('/viewuser?id=' + id)
    }
    const switch_toggle = (e, id, user_index) => {
        var temp = [...brokers_list]
        temp.find((element, index) => index === user_index).status = e === true ? "ACT" : "DIS"
        set_brokers_list(temp)
        let data = {
            "id": id,
            "status": e === true ? "ACT" : "DIS"
        }

        ApiServices.act_deact_user(selector.token, data).then(response => {
            if (response.status === 200) {
                Swal.fire(
                    '',
                    response.data.data.status === "ACT" ? "User activated succesfully." : "User deactivated succesfully.",
                    'success'
                )
            }
        }).catch(err => {
            Swal.fire(
                '',
                err.response.data.message,
                'warning'
            )
        })
    }

    const handlesearch = (event) => {
        if (event.key === 'Enter') {
            get_strata_reporter(search, page)
        }
    }

    return (
        <>

            <div class="wrapper">
                <div class="content-wrapper">
                    <section class="content-header">
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-6">
                                    <h1>Brokers</h1>
                                </div>
                                <div class="col-sm-6">
                                    {/* <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">Strata Report</li>
                </ol> */}
                                </div>
                            </div>
                        </div>
                    </section>

                    {!selector.is_loading ?
                        <section class="content">
                            <div>
                                <input className='search-field' type="text" value={search} onChange={(e) => set_search(e.target.value)} placeholder='Search user by email or name' onKeyDown={handlesearch} />
                                <FcSearch size={25} style={{ cursor: "pointer", marginLeft: "-34px" }} onClick={() => get_strata_reporter(search, page)} />
                            </div>
                            <br />
                            {brokers_list.length > 0 ?
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone Number</th>
                                            <th>Address</th>
                                            <th>Type</th>
                                            <th>Date of Joining</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {brokers_list.length !== 0 && brokers_list.map((agent, index) => (
                                            <tr>
                                                <td>{agent.name}</td>
                                                <td>{agent.email}</td>
                                                <td>{agent.phone_number}</td>
                                                <td>{agent.address}</td>
                                                <td>{agent.agent_type === "BR" && "Broker"}</td>
                                                <td>{moment(agent.createdAt).format('YYYY-MM-DD')}</td>
                                                <td>{agent.status === "ACT" ? <span style={{ color: "green" }}>Active</span> : <span style={{ color: "red" }}>Deactivated</span>}</td>
                                                <td>
                                                    <p>
                                                        <span className="mr-1"><BsEye size={25} style={{ color: "#007bff", cursor: "pointer" }} onClick={(e) => view_user(e, agent._id)} /></span>
                                                        <span><BiEdit size={25} style={{ color: "#007bff", cursor: "pointer" }} onClick={() => navigate(`/edituser?id=${agent._id}`)} /></span>
                                                        <span className="mr-1">
                                                            <DeleteButton buttonType={"user"} id={agent._id} reload={setReload} />
                                                        </span>
                                                        <span><BootstrapSwitchButton checked={agent.status === "ACT" ? true : false} size="sm" onlabel="Active" offlabel="Deactivated" onChange={(e) => switch_toggle(e, agent._id, index)} />
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>))}
                                    </tbody>
                                </table> :
                                <h4 className="text-center">No user found</h4>}
                        </section>
                        :
                        <h4 className="text-center">Loading...</h4>}
                    {count > 0 ?
                        <div className='ml-1 mt-3'>
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={page + 1}
                                itemsCountPerPage={20}
                                totalItemsCount={count}
                                pageRangeDisplayed={5}
                                prevPageText="Previous"
                                nextPageText="Next"
                                onChange={(event) => {
                                    set_page(event - 1)
                                    get_strata_reporter(search, event - 1)
                                }}
                            />
                        </div>
                        : ''}
                </div>
            </div>
        </>
    );
}
export default Brokers;


