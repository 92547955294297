import { useEffect, useState } from "react";
import { BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import { FcSearch } from 'react-icons/fc'

import { useDispatch, useSelector } from "react-redux";
import { ApiServices } from "../../../APIS/apiservices";
import { useNavigate } from "react-router-dom";
import { isloading } from "../../../Services/Store/action";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";

const CompanyList = () => {

    let navigate = useNavigate()
    let selector = useSelector((state) => state)
     const [search, set_search] = useState('')
    // const [type, set_type] = useState('')
    // const [Category_list, set_category_list] = useState([])
    const dispatch = useDispatch();
    const [page, set_page] = useState(0)
    const [count, set_count] = useState(0)
    const [list, set_list] = useState([])
    // const [show, set_show] = useState(false)

    const handlesearch = (event) => {
        if (event.key === 'Enter') {
            set_search(event.target.value)
             get_company(page,event.target.value)
            // get_list()
        }
    }

    function get_company(page,search) {
        dispatch(isloading(true))
        ApiServices.get_company(selector.token, page,search).then((response) => {
            console.log("=====response==",response)
            set_count(response.data.result_count)
            set_list(response.data.data)
            dispatch(isloading(false))
        }).catch((error) => {
            dispatch(isloading(false))
            toast.error(error.response.data.message)
            console.log({ error })
        })
    }

 

 

    function DeleteArticle(id, i) {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#007bff',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                ApiServices.delete_company(selector.token, id).then((response) => {
                    get_company(page,search)
                    toast.success('Company deleted successfully')
                }).catch((error) => {
                    toast.error(error.response.data.message)
                })
            }
        })
    }

    useEffect(() => {
         get_company( page,search)
       
    }, [])


    return (
        <div class="wrapper">
            <div class="content-wrapper">
                <section class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h4 className="mb-3">Company List</h4>
                            </div>
                        </div>
                    </div>
                </section>
                {!selector.is_loading ?
                    <section class="content">
                        <div>
                            <input className='search-field' type="text" value={search} onChange={(e) => set_search(e.target.value)} placeholder='Search company by name and email' onKeyDown={handlesearch} />
                            <FcSearch size={25} style={{ cursor: "pointer", marginLeft: "-34px" }}  onClick={()=>get_company(page,search)}/>
                        </div>
                        {/* <div className="select_list mt-2"> */}
                            {/* <label>Category</label> */}
                            {/* <select className="form-select mr-2" aria-label="Default select example" onChange={(e) => get_data(e)} value={type}>
                                <option value=''>--ALL--</option>
                                {Category_list.length > 0 && Category_list.map((category, i) => {
                                    return (<option value={category._id}>{category.category_name}</option>);
                                })}

                            </select> */}
                        {/* </div> */}
                        <br />
                        {list.length > 0 ?
                            <table>
                                <thead>
                                    <tr>
                                        <th>Company Name</th>
                                        <th>Contact Name</th>
                                        <th>Company Number</th>
                                        <th>Company Email</th>
                                        <th>Bank Account</th>
                                        <th>BSB</th>
                                        <th>Account Name</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list.length > 0 && list.map((data, index) => (
                                        <tr>
                                            <td>{data.company_name}</td>
                                            <td>{data.contact_name}</td>
                                            <td>{data.company_number}</td>
                                            <td>{data.company_email}</td>
                                            <td>{data.bank_account}</td>
                                            <td>{data.bsb}</td>
                                            <td>{data.account_name}</td>
                                            <td>
                                                <p><span className="mr-1"><BsFillPencilFill size={25} style={{ color: "#007bff", cursor: "pointer" }} onClick={() => navigate(`/company?id=${data._id}`)} /></span>
                                                    <span className="mr-1"><BsTrashFill size={25} style={{ color: "#007bff", cursor: "pointer" }} onClick={() => DeleteArticle(data._id, index)} /></span>
                                                </p></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            :
                            <h4 className="text-center">No data found</h4>}
                    </section>
                    :
                    <h4 className="text-center">Loading...</h4>}
                {count > 0 ?
                    <div className='ml-1 mt-3'>
                        <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={page + 1}
                            itemsCountPerPage={20}
                            totalItemsCount={count}
                            pageRangeDisplayed={5}
                            prevPageText="Previous"
                            nextPageText="Next"
                            onChange={(event) => {
                                set_page(event - 1)
                                 get_company(event - 1,search)
                            }}
                        />
                    </div>
                    : ''}
            </div>
        </div>
    );

}

export default CompanyList;
