import { AiOutlineDelete } from 'react-icons/ai'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import { ApiServices } from '../../../APIS/apiservices';
import { isloading } from '../../../Services/Store/action';

const DeleteButton = ({buttonType, id, reload}) => {
    const selector = useSelector((state) => state)
    const dispatch = useDispatch();

    function handleClick() {
        if (window.confirm("Are you sure you want to delete this record ?")) {
            dispatch(isloading(true))
            if (buttonType == 'report') {
                ApiServices.deleteReport(selector.token, id).then(resp => {
                    successResponse()
                }).catch(err => {
                    failureResponse()
                })
            } else if (buttonType == 'user') {
                ApiServices.deleteUser(selector.token, id).then(resp => {
                    successResponse()
                }).catch(err => {
                    failureResponse()
                })
            }
        }
    }

    function successResponse() {
        dispatch(isloading(false))
        toast.success("Record deleted successfully.")
        reload(r => !r)
    }

    function failureResponse(){
        dispatch(isloading(false))
        toast.error("Unknown error occured. Please try after some time.")
    }

    return (
        <>
            <AiOutlineDelete size={25} style={{ color: "#007bff", cursor: "pointer", marginLeft: "2px" }} onClick={handleClick} />
        </>
    )
}

export default DeleteButton