import { useState, useEffect } from "react";

const FrontPage = () => {

    return (
        <div class="wrapper">
            <div class="content-wrapper">
                <section class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h1>Front Page</h1>
                            </div>
                        </div>
                    </div>
                </section>

                {/* {!selector.is_loading ? */}
                <section class="content">

                </section>
                {/* :
                        <h4 className="text-center">Loading...</h4>} */}
            </div>
        </div>
    );

}

export default FrontPage;
