import { Routes, Route } from 'react-router-dom'
import Login from '../components/Admin/Login/Login';
import { Dashboard } from '../components/Dashboard/dashboard';
import BuildingReport from '../components/Admin/Report Management/Building&Pest_Inspection';
import StrataReport from '../components/Admin/Report Management/Strata_report';
import ValuationReport from '../components/Admin/Report Management/Valuation_report';
import PageNotFound from '../components/Common/404 notfound/404';
import Depreciation from '../components/Admin/Report Management/depreciation';
import RealStateAgent from '../components/Admin/User Management/RealEstateAgenet';
import StrataReporter from '../components/Admin/User Management/Strata_reporter';
import BuyersAgent from '../components/Admin/User Management/BuyerAgents';
import Solicitors from '../components/Admin/User Management/Solicitors';
import Viewuser from '../components/Admin/User Management/Viewuser';
import ViewReport from '../components/Admin/Report Management/ViewReport';
import UploadReport from '../components/Admin/Report Management/UploadReport';
import InsuranceList from '../components/Admin/Insurance Order Management/Insurance_List';
import PaymentList from '../components/Admin/Finance Management/Payment_List';
import RequestList from '../components/Admin/Contact Us/Request_List';
import TermCondition from '../components/Admin/CRM/TermsConditions';
import PrivacyPolicy from '../components/Admin/CRM/PrivacyPolicy';
import Profile from '../components/Admin/Profile/Profile';
import AboutUs from '../components/Admin/CRM/AboutUs';
import Blogs from '../components/Admin/CRM/Blogs';
import FAQ from '../components/Admin/CRM/faq';
import FrontPage from '../components/Admin/CRM/FrontPage';
import Testimonial from '../components/Admin/CRM/Testimonial';
import TestimonialList from '../components/Admin/CRM/TestimonialList';
import Articles from '../components/Admin/CRM/ArticlesList';
import ViewInsurance from '../components/Admin/Insurance Order Management/ViewInsurance';
import FAQList from '../components/Admin/CRM/faqlist';
import ViewPayment from '../components/Admin/Finance Management/ViewPayment';
import TEst from '../test';
import Conveyancers from '../components/Admin/User Management/Conveyancers';
import Brokers from '../components/Admin/User Management/Brokers';
import Accountants from '../components/Admin/User Management/Accountants';
import QS from '../components/Admin/Report Management/QS_report';
import { useSelector } from 'react-redux';
import EditUser from '../components/Admin/User Management/EditUser';
import Dilapidation from '../components/Admin/Report Management/dilapidation_report';
import PoolInspection from '../components/Admin/Report Management/pool-inspection';
import Handover from '../components/Admin/Report Management/handover';
import StageInspection from '../components/Admin/Report Management/stage-inspection';
import AddCompany from '../components/Admin/Company/AddCompany';
import CompanyList from '../components/Admin/Company/CompanyList';
import Insight from '../components/Admin/Insight/insight';
import PropertyDetail from '../components/Admin/Insight/property_detail';


const Routing = () => {
    const selector = useSelector((state) => state)
    return (

        <Routes>
            {(selector.is_logged_in === true || selector.is_logged_in === "true") ?
                <>
                    {/* Report Management */}
                    <Route path={'/'} element={<Login />} />
                    {/* <Route path={'/test'} element={<TEst />} /> */}
                    <Route path={'/dashboard'} element={<Dashboard />} />
                    <Route path={'/strata_report'} element={<StrataReport />} />
                    <Route path={'/building_report'} element={<BuildingReport />} />
                    <Route path={'/valuation_report'} element={<ValuationReport />} />
                    <Route path={'/depreciation'} element={<Depreciation />} />
                    <Route path={'/qs'} element={<QS />} />
                    <Route path={'/dilapidation'} element={<Dilapidation />} />
                    <Route path={'/pool-inspection'} element={<PoolInspection />} />
                    <Route path={'/handover'} element={<Handover />} />
                    <Route path={'/stage'} element={<StageInspection />} />
                    <Route path={'/view_report'} element={<ViewReport />} />
                    <Route path={'/upload_report'} element={<UploadReport />} />

                    {/* User Management */}
                    <Route path={'/real_estate_agent'} element={<RealStateAgent />} />
                    <Route path={'/strata_reporter'} element={<StrataReporter />} />
                    <Route path={'/buyers_agent'} element={<BuyersAgent />} />
                    <Route path={'/solicitors'} element={<Solicitors />} />
                    <Route path={'/conveyancers'} element={<Conveyancers />} />
                    <Route path={'/brokers'} element={<Brokers />} />
                    <Route path={'/accountants'} element={<Accountants />} />
                    <Route path={'/viewuser'} element={<Viewuser />} />
                    <Route path={'/edituser'} element={<EditUser />} />

                    {/* Insurance Order Management */}
                    <Route path={'/insurance_list'} element={<InsuranceList />} />
                    <Route path={'/view_insurance'} element={<ViewInsurance />} />

                    {/* CRM */}
                    <Route path={'/aboutus'} element={<AboutUs />} />
                    <Route path={'/privacypolicy'} element={<PrivacyPolicy />} />
                    <Route path={'/termcontion'} element={<TermCondition />} />
                    <Route path={'/blogs'} element={<Blogs />} />
                    <Route path={'/articles'} element={<Articles />} />
                    <Route path={'/faq'} element={<FAQ />} />
                    <Route path={'faq_list'} element={<FAQList />} />
                    <Route path={'/front_page'} element={<FrontPage />} />
                    <Route path={'/testimonial'} element={<Testimonial />} />
                    <Route path={'/testimonial_list'} element={<TestimonialList />} />

                    {/* Insurance Management */}
                    <Route path={'/finance_list'} element={<PaymentList />} />
                    <Route path={'/view_payment'} element={<ViewPayment />} />

                    {/* Contact Us */}
                    <Route path={'/request_list'} element={<RequestList />} />

                    {/* Profile */}
                    <Route path={'/profile'} element={<Profile />} />
                   {/* Company Management */}
                    <Route path="/company" element={<AddCompany />} />
                    <Route path="/companyList" element={<CompanyList />} />
                    <Route path="/insight" element={<Insight />} />
                    <Route path="/property_detail" element={<PropertyDetail />} />
                    
                     <Route path="*" element={<PageNotFound />} />
                </>
                :
                <Route path={'*'} element={<Login />} />
            }

        </Routes>
    );

}

export default Routing;
