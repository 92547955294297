import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { ApiServices, MEDIA_URL } from "../../../APIS/apiservices";
import { isloading } from "../../../Services/Store/action";
import Cameraimg from "../../../images/camera.png";
import { toast } from "react-toastify";


const EditUser = () => {
    const navigate = useNavigate()
    const [query, setquery] = useSearchParams()
    let selector = useSelector((state) => state)
    const dispatch = useDispatch();
    let id = query.get('id')
    const [detail, setDetail] = useState({
        image: '',
        image_file: '',
        name: '',
        email: '',
        phone: '',
        type: '',
        abn: '',
        address:'',
        agency: '',
        status: ''
    })
    const get_user_info = (token, id, search, page) => {
        dispatch(isloading(true))
        ApiServices.get_user_info(token, id, search, page).then(response => {
            if (response.status === 200) {
               
                var img = ''
                if (response.data.data.profile_image) {
                    img = MEDIA_URL + response.data.data.profile_image
                }
                setDetail({
                    ...detail, name: response.data.data.name,
                    email: response.data.data.email,
                    phone: response.data.data.phone_number,
                    image: img,
                    // type: response.data.data.agent_type === 'R' ? 'Real Estate Agent' : response.data.data.agent_type === 'S' ? 'Strata Repoter' : response.data.data.agent_type === 'B' ? 'Buyer Agent' : response.data.data.agent_type === 'C' ? 'Solicitor' : response.data.data.agent_type === "CO" ? "Conveyancer" : response.data.data.agent_type === "BR" ? "Broker" : response.data.data.agent_type === "A" ? "Accountant" : '',
                    abn: response.data.data.abn_no,
                    address: response.data.data.address,
                    agency: response.data.data.agency,
                    // status: response.data.data.status === "ACT" ? "Active" : "Deactived"

                })


                dispatch(isloading(false))
            }
        }).catch((error) => {
            console.log({ error })
            dispatch(isloading(false))
        })
    }
    const UpdateUser = (e) => {
        e.preventDefault()
        if (detail.image === '') {
            toast.error("Please upload image.")
            return false;
        }
        if (detail.name === "") {
            toast.error("Name is required.")
            return false;
        }
        if (detail.email === "") {
            toast.error("Email is required.")
            return false;
        }
        if (detail.phone === "") {
            toast.error("Phone number is required.")
            return false;
        }
        if (detail.abn === "") {
            toast.error("ABN is required.")
            return false;
        }
        if (detail.agency === "") {
            toast.error("Agency is required.")
            return false;
        }
        var payload = new FormData()
        payload.append('profile_image', detail.image_file)
        payload.append('name', detail.name)
        payload.append('email', detail.email)
        payload.append('phone_number', detail.phone)
        payload.append('abn_no', detail.abn)
        payload.append('agency', detail.agency)
        payload.append('address', detail.address)
        payload.append('user_id', id)
        ApiServices.update_user(selector.token,payload).then(res=>{
            if(res.status===200){
                toast.success("User profile updated successfully.")
                navigate(`/viewuser?id=${id}`)
            }
        }).catch(err=>{
            console.log(err)
            toast.error(err.response.data.message)
        })
    }

    useEffect(() => {
        get_user_info(selector.token, id, '', '')
    }, [])
    console.log(detail);
    return (
        <div class="content-wrapper">
            <section class="content-header">
                <div class="container-fluid">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="edit-user-left" style={{ position: "relative" }}>

                                <img src={detail.image ? detail.image : Cameraimg} alt="img"></img>

                                <input type="file" className="upload-img" onChange={(e) => setDetail({ ...detail, image: URL.createObjectURL(e.target.files[0]), image_file: e.target.files[0] })} />
                            </div>
                        </div>
                        <div className="col-md-9 edit-user-form">
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label>Name: </label>
                                    <input placeholder="Enter name here" value={detail.name} onChange={(e) => setDetail({ ...detail, name: e.target.value })} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Email: </label>
                                    <input placeholder="Enter email here" value={detail.email} onChange={(e) => setDetail({ ...detail, email: e.target.value })} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Phone: </label>
                                    <input placeholder="Enter phone number here" value={detail.phone} onChange={(e) => setDetail({ ...detail, phone: e.target.value })} />
                                </div>
                                {/* <div className="form-group col-md-6">
                                    <label>Type: </label>
                                    <input placeholder="Enter type here" value={detail.type} onChange={(e) => setDetail({ ...detail, type: e.target.value })} />
                                </div> */}
                                <div className="form-group col-md-6">
                                    <label>ABN: </label>
                                    <input placeholder="Enter abn here" value={detail.abn} onChange={(e) => setDetail({ ...detail, abn: e.target.value })} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Address: </label>
                                    <input placeholder="Enter name here" value={detail.address} onChange={(e) => setDetail({ ...detail, address: e.target.value })} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Agency: </label>
                                    <input placeholder="Enter agency here" value={detail.agency} onChange={(e) => setDetail({ ...detail, agency: e.target.value })} />
                                </div>
                                <div className="col-md-12">
                                    <button type="button" className="sub-bt" onClick={(e) => UpdateUser(e)} >Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
// 

export default EditUser;