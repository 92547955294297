import { FiSettings } from 'react-icons/fi';
import { BsDot } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom';
// import { Dropdown } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';


const Header = () => {
  let navigate = useNavigate()
  const to_dashboard = () => {
    navigate('/dashboard')
  }

  return (
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
        </li>
        {/* <li class="nav-item d-none d-sm-inline-block">
            <a style={{cursor:"pointer"}} onClick={to_dashboard} class="nav-link">Home</a>
          </li> */}
        {/* <li class="nav-item d-none d-sm-inline-block">
            <a href="#" class="nav-link">Contact</a>
          </li> */}
      </ul>

      <ul class="navbar-nav ml-auto">



        <li class="nav-item dropdown">
          {/* <i class="far fa-bell"></i> */}

          <Dropdown>
            <Dropdown.Toggle variant="" id="dropdown-basic">
              <FiSettings size="25px" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => navigate('/profile')}>Profile</Dropdown.Item>
              <Dropdown.Item onClick={() => {
                sessionStorage.clear();
                navigate('/')
              }}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* <div class="dropdown">
              <a class="dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false">
                <FiSettings size="25px" />
              </a>

              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <a class="dropdown-item" href="#">Profile</a>
                <a class="dropdown-item" onClick={() => {
                  sessionStorage.clear();
                  navigate('/')
                }}>Logout</a>
              </div>
            </div> */}

          {/* <span class="badge badge-warning navbar-badge">15</span> */}
          {/* <BsDot size="25px" style={{color:"red"}} className='notification'/> */}
          {/* <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <span class="dropdown-item dropdown-header">15 Notifications</span>
              <div class="dropdown-divider"></div>
              <a href="#" class="dropdown-item">
                <i class="fas fa-envelope mr-2"></i> 4 new messages
                <span class="float-right text-muted text-sm">3 mins</span>
              </a>
              <div class="dropdown-divider"></div>
              <a href="#" class="dropdown-item">
                <i class="fas fa-users mr-2"></i> 8 friend requests
                <span class="float-right text-muted text-sm">12 hours</span>
              </a>
              <div class="dropdown-divider"></div>
              <a href="#" class="dropdown-item">
                <i class="fas fa-file mr-2"></i> 3 new reports
                <span class="float-right text-muted text-sm">2 days</span>
              </a>
              <div class="dropdown-divider"></div>
              <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a>
            </div> */}
        </li>

      </ul>
    </nav >

  );
}

export default Header;