import { ThreeDots } from 'react-loader-spinner'

const Loader = () => {
    return (
        <div className='loader'>
            <ThreeDots className="loader-dev" color="#fff" height={80} width={80} />
        </div>
    );
}

export default Loader;
