import { useEffect, useState } from "react";
import { BsEye } from "react-icons/bs";
import { FcSearch } from 'react-icons/fc'
import { useDispatch, useSelector } from "react-redux";
import { ApiServices, MEDIA_URL } from "../../../APIS/apiservices";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isloading } from "../../../Services/Store/action";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { FaDownload } from 'react-icons/fa';

const PaymentList = () => {

    let navigate = useNavigate()
    let selector = useSelector((state) => state)
    const [search, set_search] = useState('')
    const [isEndDateDisable, setIsEndDateDisable] = useState(true);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [sDate, setSDate] = useState('');
    const [eDate, setEDate] = useState('');
    const dispatch = useDispatch();
    const [page, set_page] = useState(0)
    const [count, set_count] = useState(0)
    const [list, set_list] = useState([])
    const [file, set_file] = useState('')

    useEffect(() => {
        get_requests(search, sDate, eDate, page);
        get_file()
    }, [])

    const get_requests = (s, sd, ed, p) => {
        dispatch(isloading(true))
        ApiServices.payment_list(selector.token, s, sd, ed, p).then((response) => {
            set_count(response.data.result_count)
            set_list(response.data.data)
            dispatch(isloading(false))
        }).catch((error) => {
            console.log({ error })
            dispatch(isloading(false))
            toast.error(error.response.data.message)
        })
    }

    function get_file() {
        ApiServices.create_excel(selector.token).then((response) => {
            set_file(response.data.path)
            console.log(MEDIA_URL + response.data.path)
        }).catch((error) => {
            toast.error(error.response.data.message)
            console.log({ error })
        })
    }

    const handleStartDate = (date) => {
        setIsEndDateDisable(date ? false : true)
        setStartDate(date)
        setSDate(moment(date).format("YYYY-MM-DD"));
        setEndDate('');
    }

    const handleEndDate = (date) => {
        setEndDate(date)
        setEDate(moment(date).format("YYYY-MM-DD"));
    }

    const handlesearch = (event) => {
        if (event.key === 'Enter') {
            get_requests(search, sDate, eDate, page)
        }
    }

    function fiterfxn() {
        if (eDate) {
            get_requests(search, sDate, eDate, page)
        }
        else {
            toast.error('Please enter End Date')
        }
    }

    function clearfxn() {
        setStartDate('')
        setEndDate('')
        setSDate('')
        setEDate('')
        get_requests(search, '', '', page)
    }

    return (
        <>
            <div class="wrapper">
                <div class="content-wrapper">
                    <section class="content-header">
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-6">
                                    <h1>Payment List</h1>
                                </div>
                                <div class="select_list mr-2 mt-2">
                                    <a href={MEDIA_URL + '/' + file}><FaDownload size="30px" style={{ marginRight: '5px', color: "#007bff", cursor: "pointer" }} /></a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="d-flex flex-row">
                        <div className="col-md-4 main-search">
                            <div className="search-main">
                                <input className='search-field' type="text" value={search} onChange={(e) => set_search(e.target.value)} placeholder='Search user by request id and email' onKeyDown={handlesearch} />
                                <FcSearch size={25} style={{ cursor: "pointer", marginLeft: "-34px" }} onClick={() => get_requests(search, sDate, eDate, page)} />
                            </div>
                        </div>
                        <div className="col-md-3 date-picker" style={{ width: "100px" }}>
                            <div className="form-group mb-1">
                                <label for="formGroupExampleInput">Start date</label>
                                <ReactDatePicker
                                    className="text-field"
                                    id={"sdate"}
                                    selected={startDate}
                                    maxDate={new Date()}
                                    showDisabledMonthNavigation
                                    dateFormat={"dd/MM/yyyy"}
                                    placeholderText="DD/MM/YYYY"
                                    onChange={handleStartDate}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-3 date-picker" style={{ width: "100px" }}>
                            <div className="form-group mb-1">
                                <label for="formGroupExampleInput">End date</label>
                                <ReactDatePicker
                                    className="text-field"
                                    id={"edate"}
                                    selected={endDate}
                                    minDate={startDate}
                                    maxDate={new Date()}
                                    showDisabledMonthNavigation
                                    dateFormat={"dd/MM/yyyy"}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    onChange={handleEndDate}
                                    placeholderText="DD/MM/YYYY"
                                    disabled={isEndDateDisable}
                                />
                            </div>

                        </div>
                        {sDate && <div className="col-md-2">
                            <div className="btn-box">
                                <Button className="mr-3" onClick={() => fiterfxn()}>Filter</Button>
                                <Button className="" onClick={() => clearfxn()}>Clear</Button>
                            </div>
                        </div>}

                    </div>

                    {!selector.is_loading ?
                        <section class="content">
                            {/* <div>
                                <input className='search-field' type="text" value={search} onChange={(e) => set_search(e.target.value)} placeholder='Search user by email or name' onKeyDown={handlesearch} />
                                <FcSearch size={25} style={{ cursor: "pointer", marginLeft: "-34px" }} onClick={() => get_requests(search, sDate, eDate)} />
                            </div> */}
                            <br />
                            {list.length > 0 ?
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Transaction ID</th>
                                            <th>Request ID</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Address</th>
                                            <th>Payment Date</th>
                                            <th>Price</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list.length !== 0 && list.map((data, index) => (
                                            <tr>
                                                <td>{data.transaction_id}</td>
                                                <td>{data.request_id_no}</td>
                                                <td>{data.requesting_person_first_name + ' ' + data.requesting_person_last_name}</td>
                                                <td>{data.requesting_person_email}</td>
                                                <td>{data.address}</td>
                                                <td>{moment(data.createdAt).format('YYYY-MM-DD')}</td>
                                                <td>${data.report_price}</td>
                                                <td>
                                                    <p><span className="mr-1"><BsEye size={25} style={{ color: "#007bff", cursor: "pointer" }} onClick={() => navigate(`/view_payment?id=${data._id}`)} /></span>
                                                    </p></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                :
                                <h4 className="text-center">No data found</h4>}
                        </section>
                        :
                        <h4 className="text-center">Loading...</h4>}
                    {count > 0 ?
                        <div className='ml-1 mt-3'>
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={page + 1}
                                itemsCountPerPage={20}
                                totalItemsCount={count}
                                pageRangeDisplayed={5}
                                prevPageText="Previous"
                                nextPageText="Next"
                                onChange={(event) => {
                                    set_page(event - 1)
                                    get_requests(search, sDate, eDate, event - 1)
                                }}
                            />
                        </div>
                        : ''}
                </div>
            </div>
        </>
    );
}

export default PaymentList;
