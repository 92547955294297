import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HiDocumentReport } from 'react-icons/hi';
import { RiContactsBookFill } from 'react-icons/ri';
import { FaUserCog,FaRegBuilding,FaBuilding} from 'react-icons/fa';
import { MdRequestPage, MdPayments } from 'react-icons/md';
import { useSelector } from "react-redux";
import { MEDIA_URL } from "../../../APIS/apiservices";
import crm from '../../../images/crm.png';
import Logo from '../../../images/Logo.png'

const Sidebar = () => {

  const location = useLocation()
  const [path, set_path] = useState(location.pathname)
  const [query, set_query] = useState(location.search)

  useEffect(() => {
    set_path(location.pathname)
    set_query(location.search)
  })

  let navigate = useNavigate()
  const selector = useSelector((state) => state);

  const to_strata = (event, arg) => {

    let menuLiElements = document.querySelectorAll('.nav-icon');
    Array.from(menuLiElements).forEach((element, index) => {
      element.classList.remove("active");
    })
    //  console.log(event.target.children[0].classList)
    event.target.children[0].classList.add("active")

    if (arg === 'strata') {
      navigate('/strata_report')
    }
    if (arg === 'building') {
      navigate('/building_report')
    }
    if (arg === 'valuation') {
      navigate('/valuation_report')
    }
    if (arg === 'depreciation') {
      navigate('/depreciation')
    }
    if (arg === "QS") {
      navigate('/qs')
    }
    if(arg==="DI"){
      navigate('/dilapidation')
    }
    if(arg==="PIC"){
      navigate('/pool-inspection')
    }
    if(arg==="HI"){
      navigate('/handover')
    }
    if(arg==="SI"){
      navigate('/stage')
    }
    if (arg === 'real_estate_agent') {
      navigate('/real_estate_agent')
    }
    if (arg === 'strata_reporter') {
      navigate('/strata_reporter')
    }
    if (arg === 'buyers_agent') {
      navigate('/buyers_agent')
    }
    if (arg === 'solicitors') {
      navigate('/solicitors')
    }
    if (arg === 'conveyancers') {
      navigate('/conveyancers')
    }
    if (arg === 'brokers') {
      navigate('/brokers')
    }
    if (arg === 'accountants') {
      navigate('/accountants')
    }
    if (arg === 'strata_insurance') {
      navigate('/insurance_list?type=S')
    }
    if (arg === 'property_insurance') {
      navigate('/insurance_list?type=P')
    }
    if (arg === 'request_list') {
      navigate('/request_list')
    }
    if (arg === 'finance_list') {
      navigate('/finance_list')
    }
    if (arg === 'termcontion') {
      navigate('/termcontion')
    }
    if (arg === 'privacypolicy') {
      navigate('/privacypolicy')
    }
    if (arg === 'aboutus') {
      navigate('/aboutus')
    }
    if (arg === 'faq') {
      navigate('/faq')
    }
    if (arg === 'faq_list') {
      navigate('/faq_list')
    }
    if (arg === 'front_page') {
      navigate('/front_page')
    }
    if (arg === 'blogs') {
      navigate('/blogs')
    }
    if (arg === 'articles') {
      navigate('/articles')
    }
    if (arg === 'testimonial') {
      navigate('/testimonial')
    }
    if (arg === 'testimonial_list') {
      navigate('/testimonial_list')
    }
    if (arg === 'add_company') {
      navigate('/company')
    }
    if (arg === 'companyList') {
      navigate('/companyList')
    }
    if (arg === 'insight') {
      navigate('/insight')
    }
  }

  const to_dashboard = (event) => {
    let menuLiElements = document.querySelectorAll('.nav-icon');
    Array.from(menuLiElements).forEach((element, index) => {
      element.classList.remove("active");
    })
    // document.getElementById('report_toggle').setAttribute('aria-expanded' ,'false')
    // document.getElementById('reportManagement').className='collapse'
    navigate('/dashboard')
  }
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <div>
        <div className="user-panel pt-3 pb-3 d-flex justify-content-center">
          <div>
            <img src={Logo} className="elevation-2 main-logo" alt="User Image" onClick={(e) => to_dashboard(e)} />
          </div>
          {/* <div className="info">
            <a className="d-block">{selector.user_name}</a>
            
          </div> */}
        </div>


        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <li className="nav-item menu-open" >
              <a className="nav-link active" onClick={(e) => to_dashboard(e)}>
                <i className="nav-icon fas fa-tachometer-alt"></i>
                <p>

                  <span>Dashboard</span>
                </p>
              </a>
            </li>

            <li className="nav-item menu-open">
              <a className="nav-link active">
                {/* <i className="nav-icon fas fa-tachometer-alt"></i> */}
                <HiDocumentReport size="25px" style={{ marginRight: '2px' }} />
                <p id="report_toggle" data-toggle="collapse" data-target="#reportManagement" aria-expanded="false">

                  <span>Report Management</span>
                  <i class="fa fa-angle-right management-icon" aria-hidden="true"></i>
                  <i class="fa fa-angle-down management-icon" aria-hidden="true"></i>
                </p>
              </a>
              <div id="reportManagement" class="collapse">
                <ul className="starata-list">
                  <li onClick={(e) => to_strata(e, 'strata')} ><i className="fa fa-circle  nav-icon" aria-hidden="true"></i> Strata Report</li>
                  <li onClick={(e) => to_strata(e, 'building')} ><i className="fa fa-circle  nav-icon"></i> Building & Pest Inspection</li>
                  <li onClick={(e) => to_strata(e, 'valuation')} ><i className="fa fa-circle  nav-icon"></i> Valuation Report</li>
                  <li onClick={(e) => to_strata(e, 'depreciation')} ><i className="fa fa-circle  nav-icon"></i> Depreciation  Report</li>
                  <li onClick={(e) => to_strata(e, 'QS')} ><i className="fa fa-circle  nav-icon"></i> QS  Report</li>
                  <li onClick={(e) => to_strata(e, 'DI')} ><i className="fa fa-circle  nav-icon"></i> Dilapidation  Report</li>
                  <li onClick={(e) => to_strata(e, 'PIC')} ><i className="fa fa-circle  nav-icon"></i> Pool Inspection & Certification</li>
                  <li onClick={(e) => to_strata(e, 'HI')} ><i className="fa fa-circle  nav-icon"></i> Handover Report</li>
                  <li onClick={(e) => to_strata(e, 'SI')} ><i className="fa fa-circle  nav-icon"></i> Stage Inspection</li>
                </ul>
              </div>
            </li>


            <li className="nav-item menu-open">
              <a className="nav-link active">
                {/* <i className="nav-icon fas fa-tachometer-alt"></i> */}
                <FaUserCog size="20px" style={{ marginRight: '5px' }} />
                <p id="user_toggle" data-toggle="collapse" data-target="#demo">

                  <span>User Management</span>
                  <i class="fa fa-angle-right management-icon" aria-hidden="true"></i>
                  <i class="fa fa-angle-down management-icon" aria-hidden="true"></i>
                </p>
              </a>
              <div id="demo" class="collapse">
                <ul className="starata-list">
                  <li onClick={(e) => to_strata(e, 'real_estate_agent')}><i class="fa fa-circle  nav-icon" aria-hidden="true"></i> Real Estate Agent</li>
                  {/* <li onClick={(e) => to_strata(e, 'strata_reporter')} ><i className="fa fa-circle  nav-icon"></i>Strata Reporter</li> */}
                  <li onClick={(e) => to_strata(e, 'buyers_agent')} ><i className="fa fa-circle  nav-icon"></i> Buyers Agent</li>
                  <li onClick={(e) => to_strata(e, 'solicitors')} ><i className="fa fa-circle  nav-icon"></i> Solicitors</li>
                  <li onClick={(e) => to_strata(e, 'conveyancers')} ><i className="fa fa-circle  nav-icon"></i> Conveyancers</li>
                  <li onClick={(e) => to_strata(e, 'brokers')} ><i className="fa fa-circle  nav-icon"></i> Brokers</li>
                  <li onClick={(e) => to_strata(e, 'accountants')} ><i className="fa fa-circle  nav-icon"></i> Accountants</li>
                </ul>
              </div>
            </li>

            {/* <li className="nav-item menu-open">
              <a className="nav-link active">
                
                <MdRequestPage size="25px" style={{ marginRight: '2px' }} />
                <p id="insurance_toggle" data-toggle="collapse" data-target="#insuranceManagement" aria-expanded="false">

                  <span>Insurance Order Management</span>
                  <i class="fa fa-angle-right management-icon" aria-hidden="true"></i>
                  <i class="fa fa-angle-down management-icon" aria-hidden="true"></i>
                </p>
              </a>
              <div id="insuranceManagement" class="collapse">
                <ul className="starata-list">
                  <li onClick={(e) => to_strata(e, 'strata_insurance')} ><i className="fa fa-circle  nav-icon" aria-hidden="true"></i> Strata Insurance</li>
                  <li onClick={(e) => to_strata(e, 'property_insurance')} ><i className="fa fa-circle  nav-icon" aria-hidden="true"></i> Property Insurance</li>
                </ul>
              </div>
            </li> */}

            <li className="nav-item menu-open">
              <a className="nav-link active">
                {/* <i className="nav-icon fas fa-tachometer-alt"></i> */}
                <img className="mr-1" src={crm} alt="" width="25" />
                <p id="crm_toggle" data-toggle="collapse" data-target="#crm" aria-expanded="false">

                  <span>CRM</span>
                  <i class="fa fa-angle-right management-icon" aria-hidden="true"></i>
                  <i class="fa fa-angle-down management-icon" aria-hidden="true"></i>
                </p>
              </a>
              <div id="crm" class="collapse">
                <ul className="starata-list">
                  {/* <li onClick={(e) => to_strata(e, 'front_page')}><i className="fa fa-circle  nav-icon" aria-hidden="true"></i> Front Page</li> */}
                  <li onClick={(e) => to_strata(e, 'testimonial')}><i className={path == "/testimonial" && query == "" ? "fa fa-circle  nav-icon active" : "fa fa-circle  nav-icon"} aria-hidden="true"></i> Add Testimonials</li>
                  <li onClick={(e) => to_strata(e, 'testimonial_list')}><i className={path == "/testimonial_list" || (path == "/testimonial" && query) ? "fa fa-circle  nav-icon active" : "fa fa-circle  nav-icon"} aria-hidden="true"></i> Testimonial List</li>
                  <li onClick={(e) => to_strata(e, 'faq')}><i className={path == "/faq" && query == "" ? "fa fa-circle  nav-icon active" : "fa fa-circle  nav-icon"} aria-hidden="true"></i> Add FAQs</li>
                  <li onClick={(e) => to_strata(e, 'faq_list')}><i className={path == "/faq_list" || (path == "/faq" && query) ? "fa fa-circle  nav-icon active" : "fa fa-circle  nav-icon"} aria-hidden="true"></i> FAQs List</li>
                  <li onClick={(e) => to_strata(e, 'aboutus')}><i className="fa fa-circle  nav-icon" aria-hidden="true"></i> About Us Page</li>
                  <li onClick={(e) => to_strata(e, 'termcontion')}><i className="fa fa-circle  nav-icon" aria-hidden="true"></i> Terms & Conditions Page</li>
                  <li onClick={(e) => to_strata(e, 'privacypolicy')}><i className="fa fa-circle  nav-icon" aria-hidden="true"></i> Privacy Policy Page</li>
                  <li onClick={(e) => to_strata(e, 'blogs')}><i className={path == "/blogs" && query == "" ? "fa fa-circle  nav-icon active" : "fa fa-circle  nav-icon"} aria-hidden="true"></i> Add Articles/blogs</li>
                  <li onClick={(e) => to_strata(e, 'articles')}><i className={path == "/articles" || (path == "/blogs" && query) ? "fa fa-circle  nav-icon active" : "fa fa-circle  nav-icon"} aria-hidden="true"></i> Articles/blogs List</li>
                </ul>
              </div>
            </li>
 <li className="nav-item menu-open">
              <a className="nav-link active">
              <FaRegBuilding size="20px" style={{ marginRight: '5px' }} />
                
                <p id="company_toggle" data-toggle="collapse" data-target="#companyManagement" aria-expanded="false">

                  <span>Company</span>
                  <i class="fa fa-angle-right management-icon" aria-hidden="true"></i>
                  <i class="fa fa-angle-down management-icon" aria-hidden="true"></i>
                </p>
              </a>
              <div id="companyManagement" class="collapse">
                <ul className="starata-list">
                  <li onClick={(e) => to_strata(e, 'add_company')} ><i className="fa fa-circle  nav-icon" aria-hidden="true"></i> Add Company</li>
                  <li onClick={(e) => to_strata(e, 'companyList')} ><i className="fa fa-circle  nav-icon" aria-hidden="true"></i> Company List</li>
                </ul>
              </div>
            </li> 
            <li className="nav-item menu-open">
              <a className="nav-link active">
                {/* <i className="nav-icon fas fa-tachometer-alt"></i> */}
                <RiContactsBookFill size="25px" style={{ marginRight: '2px' }} />
                <p id="contact_toggle" data-toggle="collapse" data-target="#contact_us" aria-expanded="false">

                  <span>Contact Us Management</span>
                  <i class="fa fa-angle-right management-icon" aria-hidden="true"></i>
                  <i class="fa fa-angle-down management-icon" aria-hidden="true"></i>
                </p>
              </a>
              <div id="contact_us" class="collapse">
                <ul className="starata-list">
                  <li onClick={(e) => to_strata(e, 'request_list')}><i className="fa fa-circle  nav-icon" aria-hidden="true"></i> Request List</li>
                </ul>
              </div>
            </li>

            <li className="nav-item menu-open">
              <a className="nav-link active">
                {/* <i className="nav-icon fas fa-tachometer-alt"></i> */}
                <MdPayments size="25px" style={{ marginRight: '2px' }} />
                <p id="finance_toggle" data-toggle="collapse" data-target="#finance" aria-expanded="false">

                  <span>Finance Management</span>
                  <i class="fa fa-angle-right management-icon" aria-hidden="true"></i>
                  <i class="fa fa-angle-down management-icon" aria-hidden="true"></i>
                </p>
              </a>
              <div id="finance" class="collapse">
                <ul className="starata-list">
                  <li onClick={(e) => to_strata(e, 'finance_list')}><i className="fa fa-circle  nav-icon" aria-hidden="true"></i> Payment List</li>
                </ul>
              </div>
            </li>
            <li className="nav-item menu-open">
              <a className="nav-link active">
                {/* <i className="nav-icon fas fa-tachometer-alt"></i> */}
                <FaBuilding size="25px" style={{ marginRight: '2px' }} />
                <p id="insight_toggle" data-toggle="collapse" data-target="#insight" aria-expanded="false">

                  <span>Property Insights</span>
                  <i class="fa fa-angle-right management-icon" aria-hidden="true"></i>
                  <i class="fa fa-angle-down management-icon" aria-hidden="true"></i>
                </p>
              </a>
              <div id="insight" class="collapse">
                <ul className="starata-list">
                  <li onClick={(e) => to_strata(e, 'insight')}><i className="fa fa-circle  nav-icon" aria-hidden="true"></i> Property Insights list</li>
                </ul>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </aside>

  );
}

export default Sidebar;