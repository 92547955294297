import axios from "axios"
import { useSelector } from "react-redux"

//  const API_URL = 'http://198.211.110.165:4001/v1'
// const API_URL = 'http://192.168.2.122:4001/v1'
const API_URL = 'https://liveapi.propti.com.au/v1'

// export const MEDIA_URL = 'http://198.211.110.165:4001'
// export const MEDIA_URL = 'http://192.168.2.122:4001'
export const MEDIA_URL = 'https://liveapi.propti.com.au'


export const ApiServices = {

    AdminLogin,
    get_agents_data,
    get_user_info,
    act_deact_user,
    get_report_list,
    get_report_detail,
    upload_pdf,
    contact_us,
    insurance_list,
    update_profile,
    update_password,
    get_category_list,
    add_blog,
    reply_insurance,
    articles_list,
    delete_article,
    get_testimonial,
    delete_testimonial,
    article_detail,
    update_article,
    add_testimonial,
    update_testimonial,
    view_testimonial,
    faq_categories,
    get_insurance_details,
    add_faq,
    faq_list,
    get_faq,
    update_faq,
    delete_faq,
    get_terms_conditions,
    terms_conditions,
    get_privacy_policy,
    privacy_policy,
    payment_list,
    get_aboutus,
    update_aboutus,
    create_excel,
    user_excel_sheet,
    update_user,
    deleteReport, deleteUser,
    add_company,
    get_company,
    delete_company,
    get_property,
    get_companyDetail,
    update_company,
    get_propertyDetail

}

function AdminLogin(payload) {
    return axios.post(API_URL + '/auth/login', payload)
}
function get_agents_data(token, type, search, page) {
    if (type === '') {
        return axios.get(API_URL + '/auth/?agent_type=&search=' + search + '&size=' + 20 + '&pageNo=' + page, {
            headers: {
                'Authorization': token
            }
        })
    }
    else {
        return axios.get(API_URL + '/auth/?agent_type=' + type + '&search=' + search, {
            headers: {
                'Authorization': token
            }
        })
    }
}

function get_user_info(token, id,search,page) {
    return axios.get(API_URL + '/auth/getUserInfo?id=' + id+"&searchReport="+search+"&size="+10+"&pageNo="+page, {
        headers: {
            "Authorization": token
        }
    })
}

function act_deact_user(token, payload) {

    return axios.post(API_URL + '/auth/enableDisbaleUser', payload, {
        headers: {
            "Authorization": token
        }
    })
}

function get_report_list(token, type, search, page) {

    return axios.get(API_URL + '/report/getReportsForAdmin?order_report_type=' + type + '&search=' + search + '&pageNo=' + page + '&size=' + 20, {
        headers: {
            "Authorization": token
        }
    })

}

function get_report_detail(token, id) {
    return axios.get(API_URL + '/report/getReportDetail?reportId=' + id, {
        headers: {
            "Authorization": token
        }
    })
}

function upload_pdf(token, formData) {
    return axios.put(API_URL + '/report/', formData, {
        headers: {
            "Authorization": token
        }
    })
}

function contact_us(token, search, page) {
    return axios.get(API_URL + '/contactus/?search=' + search + '&size=' + 20 + '&pageNo=' + page, {
        headers: {
            "Authorization": token
        }
    })
}

function insurance_list(token, type, search, page) {
    return axios.get(API_URL + '/insurance?insurance_type=' + type + "&search=" + search + '&size=' + 20 + '&pageNo=' + page, {
        headers: {
            "Authorization": token
        }
    })
}

function update_profile(token, formData) {
    return axios.put(API_URL + '/auth/updateAdminProfile', formData, {
        headers: {
            "Authorization": token
        }
    })
}

function update_password(token, data) {
    return axios.put(API_URL + '/auth/changePassword', data, {
        headers: {
            "Authorization": token
        }
    })
}


function get_category_list(token) {
    return axios.get(API_URL + '/article/getCategory', {
        headers: {
            "Authorization": token
        }
    })
}

function add_blog(token, payload) {
    return axios.post(API_URL + '/article/', payload, {
        headers: {
            "Authorization": token
        }
    })
}

function reply_insurance(token, id, status) {
    return axios.patch(API_URL + `/insurance/updateRepliedStatus?insurance_id=${id}&is_replied_or_not=${status}`, {}, {
        headers: {
            "Authorization": token
        }
    })
}

function articles_list(token, search, type) {
    return axios.get(API_URL + `/article/?search=${search}&article_type=${type}`, {
        headers: {
            "Authorization": token
        }
    })
}

function delete_article(token, id) {
    return axios.delete(API_URL + `/article/?article=${id}`, {
        headers: {
            "Authorization": token
        }
    })
}

function get_testimonial(token,status, search, page) {
    return axios.get(API_URL + '/testimonial/?search=' + search + '& size=' + 20 + ' & pageNo=' + page + '&status=' +status, {
        headers: {
            "Authorization": token
        }
    })
}

function delete_testimonial(token, id) {
    return axios.delete(API_URL + `/testimonial?testimonial=${id}`, {
        headers: {
            "Authorization": token
        }
    })
}

function article_detail(token, id) {
    return axios.get(API_URL + `/article/getArticleDetail?article=${id}`, {
        headers: {
            "Authorization": token
        }
    })
}

function update_article(token, id, payload) {
    return axios.put(API_URL + `/article/?article=${id}`, payload, {
        headers: {
            "Authorization": token
        }
    })
}

function add_testimonial(token, data) {
    return axios.post(API_URL + '/testimonial/', data, {
        headers: {
            "Authorization": token
        }
    })
}

function update_testimonial(token , id ,data){
    return axios.put(API_URL + '/testimonial?testimonial='+id, data, {
        headers: {
            "Authorization": token
        }
    })
}

function view_testimonial(token, id) {
    return axios.get(API_URL + `/testimonial/getTestimonialDetail?testimonial=${id}`, {
        headers: {
            "Authorization": token
        }
    })
}

function faq_categories(token) {
    return axios.get(API_URL + '/quicklinks/getFAQCategories', {
        headers: {
            "Authorization": token
        }
    })
}

function get_insurance_details(token, id) {
    return axios.get(API_URL + '/insurance/getInsuranceDetail?insurance_id=' + id, {
        headers: {
            "Authorization": token
        }
    })
}
function add_faq(token, data) {
    return axios.post(API_URL + '/quicklinks/addFAQ', data, {
        headers: {
            "Authorization": token
        }
    })
}

function faq_list(token, search, type, page) {
    return axios.get(API_URL + `/quicklinks/getFAQ?faq_category=${type}&search=${search}&size=${20}&pageNo=${page}`, {
        headers: {
            "Authorization": token
        }
    })
}

function get_faq(token, id) {
    return axios.get(API_URL + `/quicklinks/getFAQDetail?faq_id=${id}`, {
        headers: {
            "Authorization": token
        }
    })
}

function update_faq(token, id, params) {
    return axios.put(API_URL + `/quicklinks/updateFAQ?faq=${id}`, params, {
        headers: {
            "Authorization": token
        }
    })
}

function delete_faq(token, id) {
    return axios.delete(API_URL + `/quicklinks/deleteFAQ?faq=${id}`, {
        headers: {
            "Authorization": token
        }
    })
}

function get_terms_conditions(token) {
    return axios.get(API_URL + '/quicklinks/getTermsAndConditions', {
        headers: {
            "Authorization": token
        }
    })
}

function terms_conditions(token, data) {
    return axios.post(API_URL + '/quicklinks/saveTermsAndConditions', data, {
        headers: {
            "Authorization": token
        }
    })
}

function get_privacy_policy(token) {
    return axios.get(API_URL + '/quicklinks/getPrivacyPolicy', {
        headers: {
            "Authorization": token
        }
    })
}

function privacy_policy(token, data) {
    return axios.post(API_URL + '/quicklinks/savePrivacyPolicy', data, {
        headers: {
            "Authorization": token
        }
    })
}

function payment_list(token, search, sdate, edate, page) {
    return axios.get(API_URL + `/financialpayments?search=${search}&startDate=${sdate}&endDate=${edate}&pageNo=${page}&size=${20}`, {
        headers: {
            "Authorization": token
        }
    })
}

function get_aboutus(token) {
    return axios.get(API_URL + `/quicklinks/getAboutUs`, {
        headers: {
            "Authorization": token
        }
    })
}

function update_aboutus(token, data) {
    return axios.post(API_URL + '/quicklinks/saveAboutUs', data, {
        headers: {
            "Authorization": token
        }
    })
}

function create_excel(token) {
    return axios.get(API_URL + '/financialpayments/createExcel', {
        headers: {
            "Authorization": token
        }
    })
}

function user_excel_sheet(token) {
     return axios.get(API_URL + '/auth/getUsersExcel' ,{
         headers: {
             "Authorization" :token
         }
     }) 
}

function update_user(token ,payload) {
    return axios.put(API_URL + '/auth/updateUserProfile',payload,{
        headers:{
            "Authorization":token
        }
    })
}

function deleteReport(token, id){
    return axios.delete(API_URL+ "/report/"+id, {headers:{"Authorization":token}})
}

function deleteUser(token, id){
    return axios.delete(API_URL+ "/auth/"+id, {headers:{"Authorization":token}})
}
// comany module api
function add_company(token, payload) {

    return axios.post(API_URL + '/company', payload, {
        headers: {
            "Authorization": token
        }
    })
}
function update_company(token, payload,id) {

    return axios.put(API_URL + `/company/${id}`, payload, {
        headers: {
            "Authorization": token
        }
    })
}
function get_company(token, page) {
    return axios.get(API_URL + '/company/?page=' +  page, {
        headers: {
            "Authorization": token
        }
    })
}

function delete_company(token, id) {
    return axios.delete(API_URL + `/company/${id}`, {
        headers: {
            "Authorization": token
        }
    })
}
function get_companyDetail(token, id) {
    return axios.get(API_URL + `/company/${id}`, {
        headers: {
            "Authorization": token
        }
    })
}

function get_property(token, page) {
    return axios.get(API_URL + '/freeProperty/?page=' +  page, {
        headers: {
            "Authorization": token
        }
    })
}
function get_propertyDetail(token, id) {
    return axios.get(API_URL + `/freeProperty/${id}`, {
        headers: {
            "Authorization": token
        }
    })
}