export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const UPDATE_Is_Logged_In = "UPDATE_IS_LOGGED_IN";
export const IS_LOADING = "IS_LOADING";
export const USER_NAME = "USER_NAME";
export const USER_IMAGE = "USER_IMAGE";
export const USER_EMAIL = "USER_EMAIL";

export const updatePassword = (password) => {

    return {
        type: UPDATE_PASSWORD,
        payload: password,
        info: "Updating password"
    }
}

export const updateEmail = (email) => {

    return {
        type: UPDATE_EMAIL,
        payload: email,
        info: "Updating email"
    }
}

export const isloading = (loading) => {

    return {
        type: IS_LOADING,
        payload: loading,
        info: "loading status"
    }
}

export const userimage = (image) => {
    return {
        type: USER_IMAGE,
        payload: image,
        info: "user_image"
    }
}

export const username = (name) => {
    return {
        type: USER_NAME,
        payload: name,
        info: "user_image"
    }
}

export const useremail = (email) => {
    return {
        type: USER_EMAIL,
        payload: email,
        info: "user_image"
    }
}

export const updateIsLoggedIn = (is_logged_in) => {

    return {
        type: UPDATE_Is_Logged_In,
        payload: is_logged_in,
        info: "updating is_Logged_in"
    }
}

export const updateToken = (token) => {
    return {
        type: "UPDATE_TOKEN",
        payload: token,
        info: "updating_token"

    }
}